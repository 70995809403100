import { IconButton, Stack, Tooltip } from "@mui/material";
import React, { ChangeEvent } from "react";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { Box } from "@mui/system";
import { getTranslation } from "common";
import { useTranslations } from "hooks";
import Checkbox from "@mui/material/Checkbox";
import { UserData } from "hooks/useUserDataKeys";
import Userfield from "./Userfield";

interface FieldsListItemProps {
  //correzione sul readonly
  readonly?: boolean;
  canManage: boolean;
  item: { key: string; value: UserData };
  selectedProcess: number | undefined;
  onDelete: () => void;
  onEdit: (key: string, value: unknown) => void;
  onChangeInCard: (
    e: ChangeEvent<HTMLInputElement>,
    newCheck: boolean,
    key: string
  ) => void;
  check: boolean;
}

const FieldsListItem: React.FC<FieldsListItemProps> = ({
  canManage,
  selectedProcess,
  onChangeInCard,
  check,
  item,
  onDelete,
  onEdit,
  readonly
}) => {
  const translations = useTranslations();

  const buttons = [
    // {
    //   tooltip: getTranslation(translations, "generic.edit.caption"),
    //   onClick: onEdit,
    //   icon: EditRoundedIcon,
    // },
    {
      tooltip: getTranslation(translations, "generic.delete.caption"),
      onClick: onDelete,
      icon: DeleteForeverRoundedIcon,
    },
  ];

  // const value = getUserDataValue(item.value);

  return (
    <Stack
      direction="row"
      justifyContent={"start"}
      alignItems={"center"}

    >
      <Box maxWidth={"100%"} flex={1} minWidth={0} >
        <Userfield
          name={item.key}
          value={item.value.value}
          type={item.value.type}
          onChange={(v) => onEdit(item.key, v)}
          readonly={readonly}
        />
      </Box>
      {canManage && (
        <Stack minWidth={0} flexShrink={1} justifyContent={"flex-end"} direction="row">
          {buttons.map((button, idx) => (
            <Tooltip key={idx} title={button.tooltip}>
              <IconButton
                id={`button-${idx}`}
                onClick={button.onClick}
                sx={{
                  color: "rgba(84, 189, 193, 1)",
                  borderRadius: "6px",
                }}
              >
                <button.icon />
              </IconButton>
            </Tooltip>
          ))}
          <Box
          >
            <Tooltip
              title={getTranslation(
                translations,
                "generic.showingincard.caption"
              )}
            >
              <Checkbox
                id={`button-${item.key}`}
                edge="end"
                onChange={(e, newCheck) =>
                  onChangeInCard(e, newCheck, item.key)
                }
                checked={check}
                inputProps={{
                  "aria-labelledby": `checkbox-list-label-${item.key}`,
                }}
              />
            </Tooltip>
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

export default FieldsListItem;
