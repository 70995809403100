import { aiApi } from "api";
import { create_UUID, getTranslation } from "common";
import { useEffect, useRef, useState } from "react";
import useTranslations from "./useTranslations";

const checkIfParsable = (m: string) => {
  const numTick = Array.from(m.matchAll(/```/g)).length;
  if (!numTick) {
    return true;
  }
  return numTick % 2 === 0;
};
export type AiMessage = {
  uuid: string;
  isAi: boolean;
  message: string;
  isLoading: boolean;
  hidden?: boolean;
  parsing?: boolean;
};
const useLLM = (
  url: string,
  streamMode: boolean = false,
  startPrompt: boolean = false
) => {
  const translations = useTranslations();
  const llmInputRef = useRef<HTMLInputElement>(null);
  const modelInputRef = useRef<HTMLInputElement>(null);
  const [aiAnswers, setAiAnswers] = useState<AiMessage[]>([]);
  // const [aiModel, setAiModel] = useState<string>(
  //   localStorage.getItem("ai-model") || "mistralai/codestral-2501"
  // );
  const [openAI, setOpenAI] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // useEffect(() => {
  //   localStorage.setItem("ai-model", aiModel);
  // }, [aiModel]);

  const handleSubmit = async ({ ...extra }, hideUserInput: boolean = false) => {
    let aiUUID = create_UUID();
    const prompt = llmInputRef.current?.value || "";
    if (llmInputRef.current) {
      llmInputRef.current.value = "";
    }

    setAiAnswers((p) => [
      ...p,
      {
        uuid: create_UUID(),
        isAi: false,
        message: prompt,
        hidden: hideUserInput,
        isLoading: false,
      },
      { uuid: aiUUID, isAi: true, message: "", isLoading: true },
    ]);

    setLoading(true);
    const result = await aiApi.post(
      url,
      {
        prompt: prompt,
        model: modelInputRef.current?.value || "",
        chat: aiAnswers.map(({ isAi, message }) => ({
          role: isAi ? "assistant" : "user",
          content: message,
        })),
        ...extra,
      },
      {
        responseType: streamMode ? "stream" : "json",
        onDownloadProgress: (progressEvent) => {
          if (checkIfParsable(progressEvent.target.response)) {
            setAiAnswers((p) => [
              ...p.splice(0, p.length - 1),
              {
                uuid: create_UUID(),
                isAi: true,
                message: progressEvent.target.response,
                isLoading: false,
              },
            ]);
          } else {
            setAiAnswers((p) => [
              ...p.splice(0, p.length - 1),
              {
                uuid: create_UUID(),
                isAi: true,
                message: p[p.length - 1].message,
                parsing: true,
                isLoading: false,
              },
            ]);
          }
        },
      }
    );

    setLoading(false);

    if (streamMode) {
    } else {
      const response = result.data.choices[0].message.content;

      setAiAnswers((p) => [
        ...p.splice(0, p.length - 1),
        {
          uuid: create_UUID(),
          isAi: true,
          message: response,
          isLoading: false,
        },
      ]);
    }
  };

  useEffect(() => {
    if (startPrompt) {
      const welcomeMessage = getTranslation(
        translations,
        "ai_guide_welcome.message"
      );
      setAiAnswers((p) => [
        ...p,
        {
          uuid: create_UUID(),
          isAi: true,
          message: welcomeMessage,
          isLoading: false,
        },
      ]);
      // setTimeout(() => {
      //   if (llmInputRef.current) {
      //     llmInputRef.current.value = prompt;
      //     handleSubmit({}, true);
      //   }
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    llmInputRef,
    aiAnswers,
    setAiAnswers,
    // aiModel,
    // setAiModel,
    openAI,
    setOpenAI,
    handleSubmit,
    loading,
    modelInputRef,
  };
};

export default useLLM;
