import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import DraggableTaskCard from "./DraggableTaskCard";
import {
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  Droppable,
} from "react-beautiful-dnd";
import { Task } from "model/activitiesModel";
import { getTranslation } from "../common";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslations } from "../hooks";
import noTaskTodo from "../image/img_todo.svg";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import TaskCard from "./TaskCard";
import {
  getOffsetHeightForTask,
  LABEL_OFFSET,
  TASK_CARD_HEIGHT,
} from "common/utilities";
//import { groupsApi } from "api";

// definisce le props che il componente riceve
interface ToDoPanelProps {
  userId: number;
  loading: boolean;
  labelCompleteMode: boolean;
  dragItem: Task | null;
  all: boolean;
  switchChecked: boolean;
  administrator?: boolean;
  goToDoing: (task: Task) => Promise<void>;
  setDetail: (idprocesstoken: number) => void;
  processedTasks: Task[];
  showOnlyIdUserZero: boolean;
  setShowOnlyIdUserZero: React.Dispatch<React.SetStateAction<boolean>>;
}
type RowProps = {
  index: number;
  style: Object;
};
const HEADER_HEIGHT = 65;
const ToDoPanel: React.FC<ToDoPanelProps> = ({
  all,
  labelCompleteMode,
  switchChecked,
  dragItem,
  administrator,
  goToDoing,
  setDetail,
  processedTasks,
  loading,
  showOnlyIdUserZero,
  setShowOnlyIdUserZero,
}) => {
  const translations = useTranslations();
  const listRef = useRef<List<Task>>(null);

  if (window.Cypress) {
    window.Cypress.on("scroll-todo", (idprocesstoken: number) =>
      listRef.current?.scrollToItem(idprocesstoken)
    );
  }

  const filteredTasks = useMemo(() => {
    return showOnlyIdUserZero
      ? processedTasks.filter((task) => task.assignmentmode === 2)
      : processedTasks;
  }, [showOnlyIdUserZero, processedTasks]);
  
  useEffect(() => {
    listRef.current?.resetAfterIndex(0);
  }, [filteredTasks]);

  const Row: React.FC<RowProps> = React.useMemo(
    () =>
      ({ index, style }) => {
        const task = filteredTasks[index];
        return (
          <span style={style}>
            <DraggableTaskCard
              labelCompleteMode={labelCompleteMode}
              index={index}
              key={task.idprocesstoken}
              switchChecked={switchChecked}
              all={all}
              task={task}
              handleNextStep={goToDoing}
              setDetail={(idprocesstoken) => setDetail(idprocesstoken)}
              administrator={administrator}
            />
          </span>
        );
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredTasks]
  );

  const HOD = useMemo(() => {
    return processedTasks.filter(
      (task) => task.assignmentmode === 2 && task.iduser === 0
    ).length; //&& task.iduser === 0
  }, [processedTasks]);

  useEffect(() => {
    if (HOD <= 0) {
      setShowOnlyIdUserZero(false);
    }
  }, [setShowOnlyIdUserZero, HOD]);

  return (
    <Stack
      sx={{
        flex: 1,
        maxHeight: `100%`,
        backgroundColor: (_) => _.palette.grey[200],
        borderRadius: "15px",
      }}
    >
      <Box sx={{ height: HEADER_HEIGHT }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
        >
          <div id="todo-counter">
            <Typography sx={{ flex: 1, textAlign: "start" }} variant="h6">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  //ml: "10px",
                  mt: "8px",
                }}
              >
                <div
                  style={{
                    height: "10px",
                    width: "10px",
                    borderRadius: "50%",
                    backgroundColor: "#54BDC1",
                    marginRight: "8px",
                  }}
                />
                {`${getTranslation(
                  translations,
                  "pm.label.process_status.todo"
                )} (${filteredTasks.length})`}
              </Box>
            </Typography>
          </div>
          {HOD > 0 && (
            <FormControlLabel
              sx={{
                pointerEvents: HOD > 0 ? "auto" : "none",
                opacity: HOD > 0 ? 1 : 0,
              }}
              labelPlacement="start"
              control={
                <Checkbox
                  checked={showOnlyIdUserZero}
                  onChange={() => setShowOnlyIdUserZero(!showOnlyIdUserZero)}
                  color="primary"
                  size="small"
                  id="hod-checkbox"
                />
              }
              label={getTranslation(translations, "activity.task.to.assign")}
            />
          )}
        </Stack>
        <Divider
          sx={{
            height: "3px",
            borderRadius: "10px",
            backgroundColor: (_) => _.palette.primary.main,
            mt: 3,
            mb: 3,
            width: "85%",
            mx: "auto",
          }}
        />
      </Box>
      <Droppable
        isDropDisabled={dragItem?.state === 0}
        droppableId="todo"
        mode="virtual"
        renderClone={(
          provided: DraggableProvided,
          snapshot: DraggableStateSnapshot,
          rubric: DraggableRubric
        ) => (
          <span {...provided.draggableProps} {...provided.dragHandleProps}>
            <TaskCard
              labelCompleteMode={labelCompleteMode}
              switchChecked={false}
              setDetail={(idprocesstoken) => setDetail(idprocesstoken)}
              task={dragItem || processedTasks[rubric.source.index]}
            />
          </span>
        )}
      >
        {(provided, snapshot) => (
          <Box
            sx={{
              maxHeight: `calc(100% - ${HEADER_HEIGHT}px})`,
              height: `calc(100% - ${HEADER_HEIGHT}px)`,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              pb: 2,
            }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {filteredTasks.length <= 0 && !loading && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Box
                  component="img"
                  alt="..."
                  sx={{
                    width: "208px",
                    height: "208px",
                  }}
                  src={noTaskTodo}
                />
                <Typography color="textSecondary" component="p" sx={{ mt: 1 }}>
                  {getTranslation(
                    translations,
                    "generic.no_tasks_to_do.caption"
                  )}
                </Typography>
              </Box>
            )}
            {loading && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <CircularProgress />
              </Box>
            )}
            <AutoSizer style={{ height: `calc(100% - ${HEADER_HEIGHT}px)` }}>
              {({
                height,
                width,
              }: {
                height: number;
                width: number;
                scaledWidth: number;
                scaledHeight: number;
              }) => (
                <List
                  ref={listRef}
                  height={height}
                  itemCount={loading ? 0 : filteredTasks.length}
                  itemSize={(index) =>
                    TASK_CARD_HEIGHT +
                    10 +
                    (labelCompleteMode ? LABEL_OFFSET : 0) +
                    getOffsetHeightForTask(processedTasks[index].userdataLength)
                  }
                  width={width}
                  overscanCount={5}
                >
                  {Row}
                </List>
              )}
            </AutoSizer>
          </Box>
        )}
      </Droppable>
    </Stack>
  );
};

export default ToDoPanel;
