import { Box } from "@mui/material";
import PreviewAiModel from "components/PreviewAiModel";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

interface CodeComponentProps extends React.HTMLAttributes<HTMLElement> {
  uuid: string;
  /**
   * Se `true`, indica che si tratta di inline code (racchiuso in `).
   * Se `false`, si tratta di un blocco di codice (```).
   */
  inline?: boolean;

  /** Classe che specifica la lingua (es. "language-js") */
  className?: string;

  /** Nodi figli, il contenuto del codice */
  children?: React.ReactNode;
  onApply?: (model: string) => Promise<void>;
}

type CodeComponentType = (props: CodeComponentProps) => JSX.Element | null;

const Code: CodeComponentType = ({
  onApply,
  uuid,
  inline,
  className,
  children,
  ...props
}) => {
  const match = /language-(\w+)/.exec(className || "");
  
  return !inline && match && match[1] === "xml" ? (
    <PreviewAiModel uuid={uuid} model={String(children)} onApply={onApply} />
  ) : match ? (
    <Box borderRadius={4}>
      <SyntaxHighlighter
        style={{ ...(vscDarkPlus as any) }}
        language={match[1]}
        PreTag="div"
        {...props}
      >
        {String(children).replace(/\n$/, "")}
      </SyntaxHighlighter>
    </Box>
  ) : (
    // Altrimenti, per inline code o senza lingua, semplice <code> con stile personalizzato
    <Box
      component="code"
      sx={{
        color: "warning.main",
        backgroundColor: "grey.100",
        padding: "2px 4px",
        borderRadius: 1,
        fontSize: "0.875rem",
        // Aggiungi altri stili MUI se desideri
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default Code;
