import { Close } from "@mui/icons-material";
import { DialogTitle, DialogTitleProps, IconButton } from "@mui/material";
import React from "react";

interface DialogHeaderProps extends DialogTitleProps {
  children?: React.ReactNode;
  onClose: () => void;
}

const DialogHeader: React.FC<DialogHeaderProps> = ({
  children,
  onClose,
  ...other
}) => {
  return (
    <DialogTitle
      component="div"
      {...other}
      sx={{
        m: 0,
        p: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        ...other.sx,
      }}
    >
      <div style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}>
        {children}
      </div>
      <IconButton
        id="closeicon-task"
        aria-label="close"
        onClick={onClose}
        sx={{
          flex: 0,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
    </DialogTitle>
  );
};

export default DialogHeader;
