import { Box, styled } from "@mui/material";

export default styled(Box, {
  shouldForwardProp: (props) => props !== "isAi",
})<{ isAi: boolean }>(({ theme, isAi }) => ({
  position: "relative",
  alignSelf: isAi ? "start" : "end",
  background: isAi ? theme.palette.grey[300] : theme.palette.primary.light,
  // color:
  maxWidth: "90%",
  margin: theme.spacing(1, 0),
  paddingInline: theme.spacing(2),
  color: theme.palette.common.black,
  // overflowX: "hidden",
  // backgroundColor: theme.palette.primary.main,
  borderRadius: theme.spacing(2),
  // Per arrotondare maggiormente i 4 angoli:
  // borderRadius: 16,

  // Triangolino a sinistra
  "&::before": {
    content: '""',
    // overflowX: "hidden",
    position: "absolute",
    top: theme.spacing(2),
    left: isAi ? "-18px" : "calc(100% - 2px)",
    borderWidth: "9px",
    borderStyle: "solid",
    borderColor: isAi
      ? `transparent ${theme.palette.grey[300]} transparent transparent`
      : `transparent transparent transparent ${theme.palette.primary.light}`,
  },
}));
