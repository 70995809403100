import { useCallback, useEffect, useState } from "react";

type UseResizeProps = {
  minWidth: number;
  maxWidth: number;
  defaultWidth: number;
  side: "left" | "right";
};

type UseResizeReturn = {
  width: number;
  enableResize: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const useResize = ({
  minWidth,
  defaultWidth,
  maxWidth,
  side,
}: UseResizeProps): UseResizeReturn => {
  const [isResizing, setIsResizing] = useState(false);
  const [width, setWidth] = useState(defaultWidth);

  const enableResize = useCallback(() => {
    setIsResizing(true);
  }, [setIsResizing]);

  const disableResize = useCallback(() => {
    setIsResizing(false);
  }, [setIsResizing]);

  const resize = useCallback(
    (e: MouseEvent) => {
      
      if (isResizing) {
        const newWidth =
          side === "right" ? window.innerWidth - e.clientX : e.clientX; // You may want to add some offset here from props
        if (newWidth >= minWidth && newWidth <= maxWidth) {
          setWidth(newWidth);
        }
      }
    },
    [minWidth, isResizing, setWidth, maxWidth, side]
  );
  useEffect(() => {
    if(isResizing) {
        // disablita
        
    }
    return () => {
        // riabilita
    }
  }, [isResizing]);

  useEffect(() => {
    document.addEventListener("mousemove", resize);
    document.addEventListener("mouseup", disableResize);

    return () => {
      document.removeEventListener("mousemove", resize);
      document.removeEventListener("mouseup", disableResize);
    };
  }, [disableResize, resize]);

  return { width, enableResize };
};

export default useResize;
