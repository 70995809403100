import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  IconButton,
  Collapse,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CardActionArea,
  Tooltip,
  Portal,
} from "@mui/material";
import {
  useRobots,
  useTranslations,
  useOrganizations,
  useSnackbar,
  useVersions,
  useUser,
  useLLM,
} from "hooks";
import { organizationsApi, robotsApi } from "api";
import AvatarRem from "./AvatarRem";
import {
  Delete,
  Edit,
  ExpandLess,
  ExpandMore,
  PrecisionManufacturing,
  Add,
  Backup,
} from "@mui/icons-material";
import { RobotDialog } from "./dialogs";
import { Robot } from "hooks/useRobots";
import { getTranslation } from "common";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import AiDrawer from "./aiChat/AiDrawer";

interface UserPersonalRobotsProps {
  onRobotUpdate: () => Promise<void>;
  searchedRobots: Robot[]; //per la ricerca
}

interface Category {
  id: number;
  description: string;
}

const UserPersonalRobots: React.FC<UserPersonalRobotsProps> = ({
  onRobotUpdate,
  searchedRobots,
}) => {
  const user = useUser();

  const {
    aiAnswers,
    handleSubmit: _handleSubmitLLM,
    openAI,
    setOpenAI,
    llmInputRef,
    loading: LLMLoading,
    modelInputRef
  } = useLLM("robot/code");
  const { getVersion, updateVersion } = useVersions();
  const [userRobots, getUserRobots, setUserRobots] = useRobots("user"); //hook modificato leggermente per accettare un terzo elemento
  const [userOrganizations] = useOrganizations();
  const translations = useTranslations();
  const { sendSnack } = useSnackbar();
  const [loadingOrgs, setLoadingOrgs] = useState<number[]>([]);
  const [editRobot, setEditRobot] = useState<Robot | undefined>(undefined);
  const [confirmDelete, setConfirmDelete] = useState<Robot | undefined>(
    undefined
  );
  const [expandedRobots, setExpandedRobots] = useState<number[]>([]);
  const [create, setCreate] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);

  const toggleOrganizations = (robotId: number) => {
    setExpandedRobots((prev) =>
      prev.includes(robotId)
        ? prev.filter((id) => id !== robotId)
        : [...prev, robotId]
    );
  };
  const handleEditRobot = (robot: Robot) => {
    setEditRobot(robot);
  };

  const handleDeleteRobot = async () => {
    if (!confirmDelete) return;
    try {
      const { data } = await robotsApi.delete(`${confirmDelete.id}`);
      sendSnack({
        message: data.responsemessage,
        type: "success",
      });
      setConfirmDelete(undefined);
      await getUserRobots();
      await onRobotUpdate();
    } catch {}
  };

  const onSaveRobot = async () => {
    await getUserRobots();
    await onRobotUpdate();
    setEditRobot(undefined);
  };

  const handleChangeOrganization = async (robotId: number, orgId: number) => {
    setLoadingOrgs((currentLoadingOrgs) => [...currentLoadingOrgs, orgId]);
    try {
      const robot = userRobots.find((robot) => robot.id === robotId);
      let data;
      if (
        robot?.organizations.some(
          (organization) => String(organization.id) === String(orgId)
        )
      ) {
        const response = await organizationsApi.delete(
          `${orgId}/robots/${robotId}`
        );
        data = response.data;
      } else {
        const response = await organizationsApi.put(
          `${orgId}/robots/${robotId}`
        );
        data = response.data;
      }
      sendSnack({
        message: data.responsemessage,
        type: "success",
      });
      await getUserRobots();
      await onRobotUpdate();
    } catch {
    } finally {
      setLoadingOrgs((currentLoadingOrgs) =>
        currentLoadingOrgs.filter((id) => id !== orgId)
      );
    }
  };

  const publishRobot = async (robotId: number) => {
    try {
      const response = await robotsApi.put(`/${robotId}/publish`);
      if (response.data.success) {
        sendSnack({
          message: response.data.responsemessage,
          type: "success",
        });
        onRobotUpdate();
      }
      return true;
    } catch {}
  };

  const depublishRobot = async (robotId: number) => {
    try {
      const response = await robotsApi.delete(`/${robotId}/depublish`);
      if (response.data.success) {
        sendSnack({
          message: response.data.responsemessage,
          type: "success",
        });
        onRobotUpdate();
      }

      return true;
    } catch {}
  };

  const handlePublishRobot = async (robotId: number, published: boolean) => {
    const isPublished = published
      ? await publishRobot(robotId)
      : await depublishRobot(robotId);
    if (isPublished) {
      setUserRobots((prevRobots) =>
        prevRobots.map((robot) =>
          robot.id === robotId ? { ...robot, published } : robot
        )
      );
    }
  };
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await robotsApi.get("/categories");
        if (data) {
          setCategories(data.categories);
        }
      } catch {}
    };
    fetchCategories();
  }, []);

  const handleSubmitLLM = async () => {
    _handleSubmitLLM({});
  };

  return (
    <>
      <Portal container={document.body}>
        <AiDrawer
          LLMLoading={LLMLoading}
          aiAnswers={aiAnswers}
          handleSubmitLLM={handleSubmitLLM}
          llmInputRef={llmInputRef}
          modelInputRef={modelInputRef}
          openAI={openAI}
          setOpenAI={setOpenAI}
        />
      </Portal>

      {userRobots.length === 0 && (
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          {getTranslation(translations, "no.robots")}
        </Typography>
      )}
      <Grid container spacing={2}>
        {searchedRobots.map(
          (
            robot //dalla ricerca che la fa il padre
          ) => (
            <Grid item sm={6} md={4} lg={3} xs={12} key={`robot-${robot.id}`}>
              <Card
                elevation={3}
                sx={{
                  height: "auto",
                  minHeight: 125,
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "15px",
                }}
              >
                <CardHeader
                  sx={{
                    display: "grid",
                    gridTemplateColumns:
                      "0fr minmax(20px, 1fr) minmax(50px, 0fr)",
                  }}
                  title={robot.name}
                  subheader={
                    categories.find((c) => c.id === robot.idcategory)
                      ?.description ||
                    getTranslation(translations, "generic.no.category")
                  }
                  titleTypographyProps={{
                    sx: {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                  avatar={
                    <AvatarRem
                      sx={{ width: 80, height: 80 }}
                      key={`${robot.id}-${getVersion(robot.id)}`}
                      icon={robot.picture || undefined}
                    >
                      <PrecisionManufacturing
                        sx={{ width: "50%", height: "50%" }}
                      />
                    </AvatarRem>
                  }
                  action={
                    <Box>
                      <IconButton
                        onClick={() => handleEditRobot(robot)}
                        size="small"
                        id="edit-robot-btn"
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                      {!robot.published && (
                        <IconButton
                          onClick={() => setConfirmDelete(robot)}
                          size="small"
                          id="delete-robot-btn"
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      )}
                    </Box>
                  }
                />
                <CardActions
                  disableSpacing
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    id="show-robot-details"
                    onClick={() => toggleOrganizations(robot.id)}
                    endIcon={
                      expandedRobots.includes(robot.id) ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    }
                  >
                    {expandedRobots.includes(robot.id)
                      ? getTranslation(translations, "hide.details")
                      : getTranslation(translations, "show.details")}
                  </Button>
                  <Tooltip
                    title={getTranslation(
                      translations,
                      robot.published ? "robot.published" : "robot.unpublished"
                    )}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="publish-unpublish"
                          size="small"
                          checked={robot.published}
                          onChange={(e) => {
                            handlePublishRobot(robot.id, e.target.checked);
                          }}
                          icon={<Backup />}
                          checkedIcon={<CloudDoneIcon />}
                        />
                      }
                      label=""
                    />
                  </Tooltip>
                </CardActions>
                <Collapse in={expandedRobots.includes(robot.id)}>
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      {robot.description}
                    </Typography>
                    <Stack direction="column">
                      <Typography sx={{ mt: 2, fontWeight: "bold" }}>
                        {getTranslation(
                          translations,
                          "menu.left.organizations"
                        )}
                      </Typography>
                      {userOrganizations
                        .filter(({ administrator }) => administrator)
                        .map((org) => (
                          <FormControlLabel
                            key={org.id}
                            control={
                              <Checkbox
                                size="small"
                                checked={robot.organizations.some(
                                  (organization) =>
                                    String(organization.id) === String(org.id)
                                )}
                                onChange={() =>
                                  handleChangeOrganization(
                                    robot.id,
                                    Number(org.id)
                                  )
                                }
                                disabled={loadingOrgs.includes(
                                  org.id as number
                                )}
                              />
                            }
                            label={org.name}
                          />
                        ))}
                    </Stack>
                  </CardContent>
                </Collapse>
              </Card>
            </Grid>
          )
        )}
        <Grid item sm={6} md={4} lg={3} xs={12} key="robot-add">
          <Card
            id="new-robot-btn"
            elevation={0}
            sx={{
              border: "2px dashed grey",
              minHeight: 165,
              display: "flex",
              flexDirection: "column",
              borderRadius: "15px",
            }}
          >
            <CardActionArea
              sx={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => setCreate(true)}
            >
              <Typography variant="body2">
                {getTranslation(translations, "generic.add")}
              </Typography>
              <Add htmlColor="grey" />
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        open={Boolean(confirmDelete)}
        onClose={() => setConfirmDelete(undefined)}
      >
        <DialogTitle>
          {getTranslation(translations, "generic.button.confirm")}
        </DialogTitle>
        <DialogContent>
          {getTranslation(translations, "robots.confirm.delete")}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDelete(undefined)}
            variant="contained"
            size="small"
          >
            {getTranslation(translations, "generic.button.cancel")}
          </Button>
          <Button
            onClick={handleDeleteRobot}
            variant="contained"
            size="small"
            id="confirm-delete-robot-btn"
          >
            {getTranslation(translations, "generic.button.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
      <RobotDialog
        updateVersion={updateVersion}
        onSave={onSaveRobot}
        open={Boolean(editRobot)}
        defaultRobot={editRobot}
        onClose={() => setEditRobot(undefined)}
        userId={user?.userId.toString()}
        onOpenAi={() => setOpenAI(true)}
      />
      <RobotDialog
        updateVersion={updateVersion}
        onSave={onSaveRobot}
        open={create}
        onClose={() => setCreate(false)}
        userId={user?.userId.toString()}
      />
    </>
  );
};

export default UserPersonalRobots;
