import React, { memo } from "react";
import LinkPreview from "./LinkPreview";
import Code from "./Code";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";

type MarkdownViewerProps = {
  uuid: string;
  handleApplyAi?: (model: string) => Promise<void>;
  message: string;
};

const MarkdownViewer: React.FC<MarkdownViewerProps> = ({
  uuid,
  handleApplyAi,
  message,
}) => {
  return (
    <ReactMarkdown
      key={"markdown" + uuid}
      remarkPlugins={[remarkGfm]}
      components={{
        a: (props) => {
          return <LinkPreview key={`link-preview-${uuid}`} {...props} />;
        },
        code: (props) => (
          <Code {...props} uuid={uuid} onApply={handleApplyAi} />
        ),
      }}
    >
      {message}
    </ReactMarkdown>
  );
};

export default memo(MarkdownViewer);
