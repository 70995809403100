import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Stack,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItemButton,
  ListItemText,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import AvatarRem from "./AvatarRem";
import { TaskCardProps } from "model/activitiesModel";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Tooltip from "@mui/material/Tooltip";
import InsertCommentOutlinedIcon from "@mui/icons-material/InsertCommentRounded";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltRounded";
import FolderOutlinedIcon from "@mui/icons-material/FolderRounded";
import { getTranslation } from "common";
import { useTranslations } from "hooks";
import Labels from "./labels/Labels";
import {
  getLabelDataFromJsonTags,
  getOffsetHeightForTask,
  getUserData,
  getUserDataValue,
  LABEL_OFFSET,
  TASK_CARD_HEIGHT,
} from "common/utilities";
import { baseApi } from "api";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { SupportedUserDataTypes, UserData } from "hooks/useUserDataKeys";

const TaskCard: React.FC<TaskCardProps> = ({
  task,
  labelCompleteMode,
  setDetail,
  onAssignClick,
}) => {
  const translations = useTranslations();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [groupUsers, setGroupUsers] = useState<
    {
      id: number;
      name: string;
      firstname: string;
      lastname: string;
      picture: string;
    }[]
  >([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [selectedUser, setSelectedUser] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleAssignClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setDialogOpen(true);
    setLoadingUsers(true);

    try {
      const response = await baseApi.get(
        `/tasks/processtoken/${task.idprocesstoken}/users`
      );
      setGroupUsers(response.data.users);
    } catch {
    } finally {
      setLoadingUsers(false);
    }
  };

  const handleUserSelect = (user: {
    id: number;
    firstname: string;
    lastname: string;
  }) => {
    setSelectedUser({
      id: user.id,
      name: `${user.firstname} ${user.lastname}`,
    });
    setDialogOpen(false);
    setConfirmDialogOpen(true);
  };

  const handleConfirmAssign = async () => {
    if (selectedUser) {
      try {
        await baseApi.put(
          `/tasks/processtoken/${task.idprocesstoken}/assignment/user/${selectedUser.id}`,
          {
            iduser: selectedUser.id,
          }
        );
      } catch {
      } finally {
        setConfirmDialogOpen(false);
        setSelectedUser(null);
      }
    }
  };

  const icons = [
    {
      title: getTranslation(translations, "settings.comments.label"),
      IconComponent: InsertCommentOutlinedIcon,
    },
    {
      title: getTranslation(translations, "generic.fields.caption"),
      IconComponent: ListAltOutlinedIcon,
    },
    {
      title: getTranslation(translations, "generic.attached_files.caption"),
      IconComponent: FolderOutlinedIcon,
    },
  ];

  const onButtonClickIcon = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setDetail(task?.idprocesstoken);
  };

  const typographyStyleDescription = {
    fontSize: 16,
    fontWeight: 700,
    whiteSpace: "nowrap",
    overflow: "hidden",
  };

  const chipStylePriority = {
    "& .MuiChip-label": {
      px: 0,
    },
    background: "transparent",
    fontSize: 14,
    fontWeight: "bold",
    minWidth: 45,
    px: 1,
    borderRadius: "6px",
    border: "1.5px solid rgba(84, 189, 193, 1)",
    height: 20,
    color: "black",
  };

  return (
    <React.Fragment>
      <Card
        sx={{
          position: "relative",
          height:
            TASK_CARD_HEIGHT +
            (labelCompleteMode ? LABEL_OFFSET : 0) +
            getOffsetHeightForTask(task.userdataLength),
          flex: 1,
          padding: "10px",
          width: "calc(100% - 32px)",
          borderRadius: "16px",
          overflowx: "hidden",
          display: "flex",
          // boxShadow: "none",
          // boxShadow: "0px 0px 7px 2px rgba(0,0,0,0.3)",
          cursor: "grab",
        }}
        elevation={3}
      >
        <Backdrop
          open={!!task.loading}
          component="div"
          sx={{
            position: "absolute",
            zIndex: 9999,
            inset: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Backdrop>
        <Stack sx={{ maxWidth: "100%", flex: 1 }}>
          <Stack
            flex={1}
            direction="row"
            sx={{ position: "relative", maxWidth: "100%" }}
          >
            <CardContent
              component={Stack}
              direction="column"
              flex={1}
              sx={{ maxWidth: "100%" }}
              p={"0px!important"}
            >
              <Box height={10 + (labelCompleteMode ? LABEL_OFFSET : 0)}>
                <Labels
                  labelCompleteMode={labelCompleteMode}
                  inCard
                  labels={getLabelDataFromJsonTags(task?.jsontag)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  height: "100%",
                  maxWidth: "100%",
                }}
              >
                <Stack
                  sx={{
                    maxWidth: "100%",
                    alignSelf: "flex-start",
                    flex: 1,
                    p: 0,
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    {/* Display UserData chosen by the user who retrieved it from localStorage */}
                    <Box
                      display="flex"
                      flexDirection="column"
                      sx={{ maxWidth: "60%" }}
                      alignItems="flex-start"
                    >
                      {Object.entries<UserData>(
                        getUserData(task?.userdata)
                      ).map(([key, value]) => {
                        let displayValue = `${key}: ${getUserDataValue(
                          value
                        )}`;

                        return (
                          <React.Fragment key={key}>
                            {value.inCard ? (
                              <Box
                                display="flex"
                                sx={{
                                  maxWidth: "100%",
                                  overflow: "hidden",
                                  textWrap: "nowrap",
                                }}
                                alignItems="center"
                              >
                                <Tooltip
                                  title={displayValue}
                                  placement="bottom-start"
                                >
                                  <Typography
                                    variant="subtitle2"
                                    color="text.disabled"
                                    sx={{
                                      fontSize: 12,
                                      overflow: "hidden",
                                      fontWeight: "bold",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {displayValue}
                                  </Typography>
                                </Tooltip>
                              </Box>
                            ) : null}
                          </React.Fragment>
                        );
                      })}
                    </Box>
                    <Box
                      sx={{
                        width: "40%",
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <Chip
                        sx={(theme) => ({
                          "& .MuiChip-label": {
                            px: 0,
                          },
                          fontSize: 13,
                          width: 40,
                          borderRadius: 1,
                          marginRight: 1,
                          height: 20,
                          color: theme.palette.grey[400],
                          backgroundColor: "white",
                        })}
                        label={dayjs(task?.created).format("DD/MM")}
                      />
                      {/* iconButton open Dialog */}
                      <IconButton
                        sx={(theme) => ({
                          border: `1px solid ${theme.palette.grey[200]}`,
                          borderRadius: "5px",
                          padding: "2px",
                        })}
                        className="detail-btn"
                        id={`task-detail-btn-${task?.idprocesstoken}`}
                        aria-label={getTranslation(
                          translations,
                          "generic.add_box.caption"
                        )}
                        disabled={task?.loading}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDetail(task?.idprocesstoken);
                        }}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box pt={1}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {/* Action name */}
                      {task?.flownodename &&
                        (task?.flownodename.length > 15 ? (
                          <Tooltip
                            title={task?.flownodename}
                            placement="bottom-start"
                          >
                            <Typography variant="h6" sx={{ paddingTop: "1px" }}>
                              {`${task?.flownodename.substring(0, 15)}...`}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography variant="h6" sx={{ paddingTop: "1px" }}>
                            {task?.flownodename}
                          </Typography>
                        ))}
                      {/* Priority */}
                      {task?.priority_name &&
                        (task?.priority_name.length > 6 ? (
                          <Tooltip
                            title={task?.priority_name}
                            placement="bottom-start"
                          >
                            <Chip
                              sx={chipStylePriority}
                              label={`${task?.priority_name.substring(
                                0,
                                6
                              )}...`}
                            />
                          </Tooltip>
                        ) : (
                          <Chip
                            sx={chipStylePriority}
                            label={task?.priority_name}
                          />
                        ))}
                    </Box>
                    <Typography
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "100%",
                        // wordBreak: "break-all",
                        // WebkitLineClamp: 3,
                        // display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        textWrap: "nowrap",
                      }}
                      color="text.disabled"
                    >
                      {task?.processmodelname}
                    </Typography>
                  </Box>
                  <Box py={1} flex={1} overflow="hidden">
                    {task?.description && (
                      <Typography
                        variant="subtitle2"
                        sx={{
                          ...typographyStyleDescription,
                          width: "100%",
                          textOverflow: "ellipsis",
                        }}
                        color="text.disabled"
                      >
                        {task?.description}
                      </Typography>
                    )}
                    <Divider
                      sx={{
                        borderColor: "lightgrey",
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                    />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <AvatarRem sx={{ height: 30, width: 30 }} />
                    {task.assignmentmode === 2 && task.iduser === 0 && (
                      <Tooltip
                        title={getTranslation(
                          translations,
                          "generic.assign_task_to_user"
                        )}
                      >
                        <IconButton
                          id="hod-dialog-button"
                          size="small"
                          onClick={handleAssignClick}
                        >
                          <PersonAddIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Box>
                      {icons.map((tooltip, index) => (
                        <Tooltip
                          key={index}
                          title={tooltip.title}
                          arrow
                          placement="top"
                        >
                          <IconButton
                            id={`task-detail-btn-${tooltip.title}`}
                            aria-label={`Add Box (${tooltip.title})`}
                            onClick={onButtonClickIcon}
                          >
                            <tooltip.IconComponent />
                          </IconButton>
                        </Tooltip>
                      ))}
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </CardContent>
          </Stack>
        </Stack>
      </Card>

      {/* Dialog per la selezione dell'utente */}
      <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          {getTranslation(translations, "generic.assign_task_to_user")}
        </DialogTitle>
        <DialogContent>
          {loadingUsers ? (
            <Stack>
              <CircularProgress />
            </Stack>
          ) : (
            <List>
              {groupUsers.map((user) => (
                <ListItemButton
                  key={user?.id}
                  onClick={() => handleUserSelect(user)}
                  id="hod-assigning-user"
                >
                  <AvatarRem
                    icon={user?.picture || ""}
                    sx={{ marginRight: "5px" }}
                  />
                  <ListItemText
                    primary={`${user.firstname} ${user.lastname}`}
                  />
                </ListItemButton>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <IconButton onClick={() => setDialogOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>

      {/* Dialog di conferma */}
      <Dialog
        open={isConfirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>
          {getTranslation(translations, "generic.confirm_task_assignment")}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {getTranslation(translations, "generic.assigning_task_to_user")}{" "}
            {selectedUser?.name}
          </Typography>
        </DialogContent>
        <DialogActions>
          <IconButton onClick={() => setConfirmDialogOpen(false)}>
            <CloseIcon />
          </IconButton>
          <IconButton
            id="hod-confirms-user"
            onClick={handleConfirmAssign}
            color="primary"
          >
            <CheckIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default TaskCard;
