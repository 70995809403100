import { Task } from "model/activitiesModel";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Gantt, ViewMode } from "gantt-task-react";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { DateRangePickerMenu } from "components";
import { DateRangePickerValue } from "components/DateRangePickerMenu";
import dayjs from "dayjs";
import { statisticsApi } from "api";
import {
  useProcessModels,
  useSelectedOrganization,
  useTranslations,
  useUser,
} from "hooks";
import { getQueryParams, getTranslation } from "common";
import { StartedProcess } from "components/ProcessItem";
import "gantt-task-react/dist/index.css";
import CustomTable from "components/statistics/CustomTable";
import CustomTooltip from "components/statistics/CustomTooltip";
import { ExtendedTask } from "model/statisticsModel";
import { Download, Expand, VerticalAlignCenter } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import NoStatistics from "image/NoStatistics.png";
import PerformanceView from "components/PerformanceView";
import { TaskDetailDialog } from "components/dialogs";

interface HistoryQueryParams {
  modelid?: number;
  datefrom: string;
  dateto: string;
  flat?: boolean;
}
interface ProcessData {
  idprocess: number;
  process_idprocessmodel: number;
  process_description: string;
  process_userdata: string;
  process_startdate: string;
  process_enddate: string;
  processduration: number;
}

const Statistics = () => {
  const translations = useTranslations();
  const [viewType, setViewType] = useState<"gantt" | "quantitative">("quantitative");
  const [selectedTask, setSelectedTask] = useState<Task | undefined>(undefined);
  const [loadingData, setLoadingData] = useState(false);
  const [interval, setInterval] = useState<DateRangePickerValue>(() => {
    const savedInterval = localStorage.getItem("dateInterval");
    if (savedInterval) {
      const parsedInterval = JSON.parse(savedInterval);
      return {
        start: dayjs(parsedInterval.start),
        end: dayjs(parsedInterval.end),
      };
    }
    return {
      start: dayjs().startOf("day"),
      end: dayjs().endOf("day"),
    };
  });
  const viewModeLabels = {
    [ViewMode.Day]: getTranslation(translations, "generic.day.caption"),
    [ViewMode.HalfDay]: getTranslation(translations, "generic.halfday.caption"),
    [ViewMode.Hour]: getTranslation(translations, "generic.hour.caption"),
    [ViewMode.Month]: getTranslation(translations, "generic.month.caption"),
    [ViewMode.QuarterDay]: getTranslation(
      translations,
      "generic.quarterday.caption"
    ),
    [ViewMode.Week]: getTranslation(translations, "generic.week.caption"),
    [ViewMode.Year]: getTranslation(translations, "generic.year.caption"),
  };
  const user = useUser();
  const selectedOrganization = useSelectedOrganization()
  const isAdmin = selectedOrganization?.administrator
  const locale = user?.i18n || navigator.language.split("-")[0];
  const [model, setModel] = useState<number | null>(null);
  const [processModelsHistory, setProcessModelsHistory] = useState<{
    [key: number]: {
      name: string;
      processes: {
        details: StartedProcess[];
        process: ProcessData;
      }[];
    };
  }>({});
  const theme = useTheme();
  const { processModels, modelsLoading } = useProcessModels();
  const [collapsed, setCollapsed] = useState<{ [key: string]: boolean }>({});
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Day);
  const [loading, setLoading] = useState<boolean>(false);
  const organization = useSelectedOrganization();
  const [flatData, setFlatData] = useState<any[]>([]);

  const updateInterval = (newInterval: DateRangePickerValue) => {
    localStorage.setItem("dateInterval", JSON.stringify(newInterval));
    setInterval(newInterval);
  };

  useEffect(() => {
    setLoadingData(true);
    if (viewType !== "gantt") {
      (async () => {
        if (organization) {
          const payload: HistoryQueryParams = {
            datefrom: (interval.start as dayjs.Dayjs).toISOString(),
            dateto: (interval.end as dayjs.Dayjs).toISOString(),
            flat: true,
          };
          try {
            const { data } = await statisticsApi.get(
              `history/${organization.id}?${getQueryParams(payload)}`
            );
            setFlatData(data);
          } catch { }
          finally {
            setLoadingData(false)
          }
        }
      })();
    } else {
      (async () => {
        setProcessModelsHistory([]);
        if (organization) {
          const payload: HistoryQueryParams = {
            datefrom: (interval.start as dayjs.Dayjs).toISOString(),
            dateto: (interval.end as dayjs.Dayjs).toISOString(),
            flat: false,
          };
          if (model) {
            payload["modelid"] = model;
          }
          try {
            const { data } = await statisticsApi.get(
              `history/${organization.id}?${getQueryParams(payload)}`
            );
            setProcessModelsHistory(data);
          } catch { } finally { setLoadingData(false) }
        }
      })();
    }
  }, [organization, interval, model, viewType]);



  const tasks = useMemo(() => {
    const _tasks: ExtendedTask[] = [];
    let minStart = null;
    let maxEnd = null;
    for (const [idProcessModel, { processes, name }] of Object.entries(
      processModelsHistory
    )) {
      const idxForModel = _tasks.length;
      for (const { process, details } of processes.filter(
        (x) => x.process.process_startdate && x.process.process_enddate
      )) {
        _tasks.push({
          taskType: "process",
          name: process.process_description,
          workingSeconds: process.processduration,
          type: "project",
          styles: {
            backgroundColor: theme.palette.secondary.dark,
            backgroundSelectedColor: theme.palette.secondary.dark,
            progressColor: theme.palette.secondary.main,
            progressSelectedColor: theme.palette.secondary.main,
          },
          hideChildren: Boolean(collapsed[process.idprocess]),
          taskProjectName: name,
          project: `${idProcessModel}`,
          id: `${process.idprocess}`,
          progress: 100,
          start: dayjs(process.process_startdate).toDate(),
          end: dayjs(process.process_enddate).toDate(),
        });
        let prevIdProcessToken = process.idprocess;
        for (const detail of details.filter((x) => x.created && x.done)) {
          const startTaskDate = dayjs(detail.created).toDate();
          const endTaskDate = dayjs(detail.done).toDate();
          if (!minStart || startTaskDate < minStart) {
            minStart = startTaskDate;
          }
          if (!maxEnd || endTaskDate > maxEnd) {
            maxEnd = endTaskDate;
          }
          _tasks.push({
            userfullname: detail.userfullname,
            taskType: "activity",
            idprocess: process.idprocess,
            description: process.process_description,
            styles: {
              backgroundColor: theme.palette.primary.main,
              backgroundSelectedColor: theme.palette.primary.main,
              progressColor: theme.palette.primary.dark,
              progressSelectedColor: theme.palette.primary.dark,
            },
            start: startTaskDate,
            end: endTaskDate,
            name: detail.flownodename || "", // added condition on taskdetaildialog for this "name" (should be flownodename only?)
            type: "task",
            waitingSeconds: detail.timetotakeover,
            workingSeconds: detail.taskduration || 0,
            progress:
              (detail.timetotakeover * 100) /
              (detail.timetotakeover + (detail.taskduration || 0)),
            // timetotakeover : x = (timetotakeover + taskduration) : 100
            project: `${process.idprocess}`,
            taskProjectName: process.process_description,
            dependencies: [`${prevIdProcessToken}`],
            id: `${detail.idprocesstoken}`,
          });
          prevIdProcessToken = detail.idprocesstoken;
        }
      }
      _tasks.splice(idxForModel, 0, {
        taskType: "processmodel",
        start: minStart || new Date(),
        end: maxEnd || new Date(),
        styles: {
          backgroundColor: theme.palette.success.main,
          backgroundSelectedColor: theme.palette.success.main,
          progressColor: theme.palette.success.main,
          progressSelectedColor: theme.palette.success.main,
        },
        name,
        progress: 100,
        hideChildren: Boolean(collapsed[`${idProcessModel}`]),
        type: "project",
        id: `${idProcessModel}`,
      });
    }
    return _tasks;
  }, [processModelsHistory, theme, collapsed]);

  const collapseAll = () => {
    const _collapsed: { [key: string]: boolean } = {};
    for (const task of tasks) {
      if (task.taskType !== "activity") {
        _collapsed[task.id] = true;
      }
    }
    setCollapsed(_collapsed);
  };

  const expandAll = () => {
    setCollapsed({});
  };

  const formatDateShort = useCallback((value: Date, includeTime?: boolean) => {
    return dayjs(value).format(
      includeTime ? "DD/MM/YYYY HH:mm:ss" : "DD/MM/YYYY"
    );
  }, []);
  const handleDownloadCSV = async () => {
    try {
      setLoading(true);
      if (organization) {
        const payload: HistoryQueryParams = {
          datefrom: (interval.start as dayjs.Dayjs).toISOString(),
          dateto: (interval.end as dayjs.Dayjs).toISOString(),
        };
        if (model) {
          payload["modelid"] = model;
        }
        const { data } = await statisticsApi.get(
          `history/${organization.id}?${getQueryParams({
            ...payload,
            csv: true,
          })}`
        );
        const blob = new Blob([data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute(
          "download",
          `${getTranslation(translations, "menu.left.statistics")}.csv`
        );
        a.click();
      }
    } catch {
    } finally {
      setLoading(false);
    }
  };

  return (
    organization && isAdmin ? (
      <Stack direction="column" spacing={1}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          {/* <UserAvatar showUsername user={user} /> */}
          <Stack direction="row" spacing={1}>
            <Button
              sx={{ ml: 2 }}
              size="small"
              variant={viewType === "quantitative" ? "contained" : "outlined"}
              onClick={() => setViewType("quantitative")}
            >
              {getTranslation(translations, "general.performance")}
            </Button>
            <Button
              size="small"
              variant={viewType === "gantt" ? "contained" : "outlined"}
              onClick={() => setViewType("gantt")}
            >
              Gantt
            </Button>
            {/* <Button
              size="small"
              variant={viewType === "performance" ? "contained" : "outlined"}
              onClick={() => setViewType("performance")}           >
              Performance
            </Button> */}
            <LoadingButton
              variant="outlined"
              startIcon={<Download />}
              onClick={handleDownloadCSV}
              loading={loading}
            >
              <span>
                {getTranslation(translations, "settings.download.label")}
              </span>
            </LoadingButton>
            {viewType === "gantt" && (
              <>
                <ButtonGroup variant="outlined" color="primary">
                  <Tooltip
                    title={getTranslation(
                      translations,
                      "generic.expandall.caption"
                    )}
                  >
                    <Button onClick={expandAll}>
                      <Expand />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={getTranslation(
                      translations,
                      "generic.collapseall.caption"
                    )}
                  >
                    <Button onClick={collapseAll}>
                      <VerticalAlignCenter />
                    </Button>
                  </Tooltip>
                </ButtonGroup>

                <TextField
                  select
                  sx={{ width: 200 }}
                  size="small"
                  label={getTranslation(translations, "generic.view.caption")}
                  value={viewMode}
                  onChange={({ target }) => setViewMode(target.value as ViewMode)}
                >
                  {Object.values(ViewMode).map((value) => (
                    <MenuItem key={`view-mode-${value}`} value={value}>
                      {viewModeLabels[value]}
                    </MenuItem>
                  ))}
                </TextField>
                {!modelsLoading && (
                  <Tooltip
                    placement="top"
                    title={processModels.find((_) => _.id === model)?.name}
                  >
                    <TextField
                      select
                      sx={{ width: 200 }}
                      label={getTranslation(
                        translations,
                        "pm.modelname.label.caption"
                      )}
                      size="small"
                      onChange={({ target }) => setModel(Number(target.value))}
                      value={model || ""}
                    >
                      <MenuItem value="">
                        {getTranslation(translations, "generic.all")}
                      </MenuItem>
                      {processModels.map((processModel) => (
                        <MenuItem
                          key={`option-${processModel.id}`}
                          value={processModel.id}
                        >
                          {processModel.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Tooltip>
                )}
              </>)}
          </Stack>
          <DateRangePickerMenu
            showDefaultIntervals
            value={interval}
            // onChange={setInterval}
            onChange={updateInterval}
          />
        </Stack>
        <Box>
          {loadingData ? (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ height: "50vh" }}
            >
              <CircularProgress />
              <Typography mt={2}>{getTranslation(translations, "generic.loading")}</Typography>
            </Stack>
          ) : viewType === "gantt" ? (
            <Box>
              {tasks.length > 0 ? (
                <Gantt
                  locale={locale}
                  viewMode={viewMode}
                  onDoubleClick={({ id }) =>
                    setCollapsed((_) => ({ ..._, [id]: !_[id] }))
                  }
                  onExpanderClick={({ id }) =>
                    setCollapsed((_) => ({ ..._, [id]: !_[id] }))
                  }
                  tasks={tasks}
                  TooltipContent={(props) => (
                    <CustomTooltip {...props} formatDate={formatDateShort} />
                  )}
                  TaskListHeader={() => null}
                  TaskListTable={(props) => (
                    <CustomTable
                      {...props}
                      formatDateShort={formatDateShort}
                      onRowClick={(task) => {
                        if (task.type === "task" && task.taskType === "activity") {
                          setSelectedTask(task as unknown as Task);
                        }
                      }}
                    />
                  )}
                />
              ) : (
                <Container
                  sx={{
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                    gap: 2,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  maxWidth="md"
                >
                  <Box
                    component="img"
                    height="50dvh"
                    width="auto"
                    sx={{ borderRadius: 9999 }}
                    src={NoStatistics}
                    alt="no scheduled process"
                  />
                  <Typography fontWeight="bold">
                    {getTranslation(translations, "generic.nostatistics.message")}
                  </Typography>
                </Container>
              )}
            </Box>
          ) : (
            <PerformanceView data={flatData} interval={interval} />
          )}
        </Box>
        <TaskDetailDialog
          readonly
          open={!!selectedTask}
          onClose={() => setSelectedTask(undefined)}
          task={selectedTask}
          doing
        // refresh
        />
      </Stack>
    ) : (<Typography>{getTranslation(translations, "generic.no.admin.permit")}</Typography>)
  );
};

export default Statistics;
