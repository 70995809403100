import {
  Box,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  List,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext, useMemo, useRef } from "react";
import DrawerHeader from "./DrawerHeader";
import { ChevronRight, Send } from "@mui/icons-material";
import useAutoScroll from "hooks/useAutoScroll";
import ChatBubble from "./ChatBubble";
import { LoadingButton } from "@mui/lab";
import { AiMessage } from "hooks/useLLM";
import useResize from "hooks/useResize";
import { UtilityContext } from "contexts";
import MarkdownViewer from "./MarkdownViewer";
import { HEADER_HEIGHT } from "common/theme";
import { getTranslation } from "common";
import { useTranslations } from "hooks";
function pauseEvent(e: any) {
  if (e.stopPropagation) e.stopPropagation();
  if (e.preventDefault) e.preventDefault();
  e.cancelBubble = true;
  e.returnValue = false;
  return false;
}
interface AiDrawerProps {
  variant?: "persistent" | "permanent" | "temporary";
  aiAnswers: AiMessage[];
  handleSubmitLLM: () => void;
  openAI: boolean;
  setOpenAI: (value: React.SetStateAction<boolean>) => void;
  LLMLoading: boolean;
  handleApplyAi?: (model: string) => Promise<void>;
  llmInputRef: React.RefObject<HTMLInputElement>;
  modelInputRef: React.RefObject<HTMLInputElement>;
}

const AiDrawer: React.FC<AiDrawerProps> = ({
  aiAnswers,
  handleSubmitLLM,
  openAI,
  setOpenAI,
  handleApplyAi,
  LLMLoading,
  llmInputRef,
  modelInputRef,
  variant,
}) => {
  const translations = useTranslations();
  const {
    state: { modelChoice },
  } = useContext(UtilityContext);
  const questions = useMemo(
    () => aiAnswers.filter(({ isAi }) => !isAi),
    [aiAnswers]
  );
  const questionSelectedIdxRef = useRef(0);
  const { enableResize, width } = useResize({
    minWidth: 100,
    defaultWidth: 500,
    side: "right",
    maxWidth: (window.innerWidth * 60) / 100,
  });

  const listRef = useAutoScroll(true, []);

  return (
    <Drawer
      sx={{
        width,
        flexShrink: 0,
        zIndex: 9999999,
        overflowX: "hidden",
        "& .MuiDrawer-paper": {
          mt: HEADER_HEIGHT,
          height: `calc(100% - ${HEADER_HEIGHT})`,
          width,
          boxSizing: "border-box",
        },
      }}
      variant={variant}
      anchor="right"
      open={openAI}
    >
      <div
        id="dragger"
        onMouseDown={(e) => {
          e = e || window.event;
          pauseEvent(e);
          enableResize(e);
        }}
        style={{
          width: "5px",
          cursor: "ew-resize",
          padding: "4px 0 0",
          borderTop: "1px solid #ddd",
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          zIndex: "100",
          backgroundColor: "#f4f7f9",
        }}
      />
      <DrawerHeader sx={{ justifyContent: "start" }}>
        <IconButton onClick={() => setOpenAI(false)}>
          <ChevronRight />
        </IconButton>
        {modelChoice && (
          <TextField
            size="small"
            fullWidth
            label=" AI model"
            inputRef={modelInputRef}
          />
        )}
      </DrawerHeader>
      <Divider />
      <List
        ref={listRef}
        sx={{
          overflowX: "hidden",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          py: 2,
          px: 4,
          overflow: "auto",
          flexShrink: 0,
        }}
      >
        {aiAnswers.map(
          ({ uuid, isAi, message, isLoading, hidden, parsing }) =>
            !hidden && (
              <React.Fragment key={uuid}>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <ChatBubble isAi={isAi}>
                    <MarkdownViewer
                      handleApplyAi={handleApplyAi}
                      message={message}
                      uuid={uuid}
                    />
                    {Boolean(parsing) && (
                      <Skeleton
                        animation="pulse"
                        width={"100%"}
                        sx={{
                          maxWidth: width - 100,
                        }}
                        height={160}
                      />
                    )}
                  </ChatBubble>
                )}
              </React.Fragment>
            )
        )}
      </List>
      <Box sx={{ p: 2, mb: 10 }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmitLLM();
          }}
        >
          <Stack direction="row" spacing={2}>
            <TextField
              inputRef={llmInputRef}
              size="small"
              onKeyDown={(e) => {
                if (["ArrowUp", "ArrowDown"].includes(e.code)) {
                  if (e.code === "ArrowUp") {
                    questionSelectedIdxRef.current =
                      questionSelectedIdxRef.current + 1 > questions.length
                        ? questions.length
                        : questionSelectedIdxRef.current + 1;
                  } else if (e.code === "ArrowDown") {
                    questionSelectedIdxRef.current =
                      questionSelectedIdxRef.current - 1 < 0
                        ? 0
                        : questionSelectedIdxRef.current - 1;
                  }
                  if (llmInputRef.current) {
                    if (questionSelectedIdxRef.current === 0) {
                      llmInputRef.current.value = "";
                    } else {
                      llmInputRef.current.value =
                        questions[
                          questions.length - questionSelectedIdxRef.current
                        ].message;
                    }
                  }
                }
              }}
              sx={{ flex: 1 }}
              // onChange={(e) => setUserPrompt(e.target.value)}
              // value={userPrompt}
              placeholder={getTranslation(translations, "ask_ai_placeholder")}
            />
            <LoadingButton loading={LLMLoading} type="submit">
              <Send />
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    </Drawer>
  );
};
// mi sono appena creato un'organizzazione, come vado avanti?
export default AiDrawer;
