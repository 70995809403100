import React, { useRef, useEffect } from "react";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { getTranslation } from "../common";
import { useTranslations } from "hooks";
import { CloudUploadRounded } from "@mui/icons-material";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

interface DragDropProps {
  onFileChange: (files: File[]) => void;
  files: File[];
}
const DropZone: React.FC<DragDropProps> = ({ onFileChange, files }) => {
  // const [files, setFiles] = useState<File[]>([]);
  const fileInput = useRef<HTMLInputElement>(null);
  const translations = useTranslations();

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files);
    // setFiles(newFiles);
    onFileChange([...files, ...newFiles]);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      // setFiles(newFiles);
      onFileChange([...files, ...newFiles]);
    }
  };

  useEffect(() => {
    return () => {
      // setFiles([]);
      onFileChange([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = (index: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    // setFiles(updatedFiles);
    onFileChange(updatedFiles);
  };

  return (
    <div>
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        style={{
          border: "2px dashed #ccc",
          borderRadius: "10px",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <Typography sx={{ pb: 4 }} variant="body1">
          Carica o trascina qui i tuoi file
        </Typography>
        <input
          type="file"
          multiple
          onChange={handleFileInputChange}
          style={{ display: "none" }}
          ref={fileInput}
        />
        <Button
          id="upload-attachment-button"
          size="small"
          startIcon={<CloudUploadRounded />}
          onClick={() => {
            fileInput.current?.click();
          }}
          sx={{
            backgroundColor: "FFFFFF",
            border: "1px solid #DEE2E6",
            "&:hover": {
              backgroundColor: "",
            },
          }}
        >
          {getTranslation(translations, "generic.select_file.caption")}
        </Button>
      </div>
      <List
        sx={{
          maxHeight: "140px",
          overflowX: "auto",
        }}
      >
        {files.map((file, index) => (
          <ListItem key={index}>
            <ListItemText>
              <AttachFileOutlinedIcon />
              <span>{file.name}</span>
            </ListItemText>
            <Tooltip title="Elimina">
              <IconButton
                onClick={() => onDelete(index)}
                sx={{
                  color: "rgba(84, 189, 193, 1)",
                  borderRadius: "6px",
                  backgroundColor: "rgba(84, 189, 193, 0.3)",
                  "&:hover": {
                    backgroundColor: "rgba(84, 189, 193, 0.4)",
                  },
                }}
              >
                <DeleteForeverRoundedIcon />
              </IconButton>
            </Tooltip>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default DropZone;
