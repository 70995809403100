import axios, { AxiosError } from "axios";
import { addSnackbar } from "Redux/snackbarReducer";
import store from "../store";

const errorInterceptor = (error: AxiosError<any>) => {
  if (!axios.isCancel(error)) {
    const message = error.response
      ? error.response.data.message ||
      error.response.data.responsemessage ||
      error.response.data.detail
      : error.message;
    if (typeof message === "string") {
      store.dispatch(
        addSnackbar({
          message: message,
          type: "error",
        })
      );
    }
    if (error.response?.status === 401) {
      window.dispatchEvent(new CustomEvent("logout"));
    }
    console.error(message);
    if (error.response?.status !== 401) {
      throw error;
    }
  } else {
    throw error
  }
  // return response;
};
export default errorInterceptor;
