import {
  AddCircleRounded,
  ArrowDropDown,
  DeleteForeverRounded,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import DialogHeader from "./dialogs/DialogHeader";
import { getTranslation } from "common";
import { useForm, useTranslations } from "hooks";
import { Validation, isRequired } from "hooks/useForm";
import FieldsListItem from "./FieldsListItem";
import { SupportedUserDataTypes, UserData } from "hooks/useUserDataKeys";
import { Data } from "./dialogs/ProcessModelSchema";
import { getUserSchema } from "common/utilities";
import Userfield from "./Userfield";

interface FieldsListProps {
  canManage?: boolean;
  readonly?: boolean;
  onChange: (newValues: { [key: string]: UserData }) => void;
  value: { [key: string]: UserData };
  schema: string | undefined;
  selectedProcess: number | undefined;
  handleSaveUserdata?: (
    newUserdata: { [key: string]: UserData },
    silentUpdate?: boolean
  ) => Promise<void>;
  linkChange?: boolean;
  enableUndefined?: boolean;
}

const FieldsList: React.FC<FieldsListProps> = ({
  readonly,
  canManage = true,
  schema,
  selectedProcess,
  onChange,
  value,
  handleSaveUserdata: _handleSaveUserData,
  linkChange = false,
  enableUndefined = false,
}) => {
  const [loading, setLoading] = useState(false);
  const handleSaveUserdata = async (
    newUserdata: { [key: string]: UserData },
    silentUpdate?: boolean
  ) => {
    if (_handleSaveUserData) {
      setLoading(true);
      await _handleSaveUserData(newUserdata, silentUpdate);
      setLoading(false);
    }
  };
  const [check, setCheck] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState<string | null>(null);
  const translations = useTranslations();
  const userSchema: Data[] = useMemo(() => getUserSchema(schema), [schema]);
  const validations: Validation[] = [
    ({ key }) =>
      isRequired(key) || {
        key: getTranslation(translations, "register.fieldrequired"),
      },
    ({ value, type }) =>
      type !== SupportedUserDataTypes.boolean &&
      (isRequired(value) || {
        value: getTranslation(translations, "register.fieldrequired"),
      }),
    ({ key }) =>
      Boolean(edit) ||
      !(key in value) || {
        key: getTranslation(
          translations,
          "generic.keypresent.metadata.caption"
        ),
      },
  ];
  const { values, changeHandler, errors, touched, isValid, reset } = useForm(
    {
      key: "",
      value: "",
      type: SupportedUserDataTypes.string,
    },
    validations
  );
  const onChangeInCard = (_: ChangeEvent, newCheck: boolean, key: string) => {
    // const newValue = newCheck ? key : null;
    setCheck((prev) => {
      let cp = [...prev];
      if (newCheck) {
        cp = [...cp, key];
      } else {
        // Copy Prev -> cp
        cp.splice(
          cp.findIndex((k) => k === key),
          1
        );
      }
      if (cp.length > 0) {
        localStorage.setItem(`userdata-per-${selectedProcess}`, cp.join(";"));
      } else {
        localStorage.removeItem(`userdata-per-${selectedProcess}`);
      }
      return cp;
    });

    handleSaveUserdata({
      ...value,
      [key]: { ...value[key], inCard: newCheck },
    });
  };

  const onChangeInCardSchema = (newCheck: boolean, name: string) => {
    handleSaveUserdata({
      ...value,
      [name]: { ...value[name], inCard: newCheck },
    });
  };
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    let newUserdata;
    const { key, ..._values } = values;
    _values.inCard = check.includes(key);
    if (_values.type === SupportedUserDataTypes.boolean && !_values.value) {
      _values.value = "false";
    }
    if (edit) {
      const { [edit]: _, ...newValue } = { ...value };
      newUserdata = { ...newValue, [key]: _values };
    } else {
      newUserdata = { ...value, [key]: _values };
    }
    onChange(newUserdata);
    setOpen(false);
    handleSaveUserdata(newUserdata);
  };

  const onDelete = (key: string) => {
    const { [key]: _, ...newValue } = { ...value };
    onChange(newValue);
    handleSaveUserdata(newValue);
  };

  useEffect(() => {
    const actualStorage = localStorage.getItem(
      `userdata-per-${selectedProcess}`
    );
    if (actualStorage) {
      setCheck(actualStorage.split(";"));
    }
    if (!open) {
      reset();
      setEdit(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onChangeSchemaValues = (field: string, _value: unknown) => {
    const fieldSchema = userSchema.find((a) => a.name === field);
    let userdata: UserData;
    if (fieldSchema) {
      userdata = {
        inCard:
          field in value ? Boolean(value[field]?.inCard) : fieldSchema.inCard,
        type: fieldSchema.type,
        value: String(_value),
      };
    } else {
      userdata = {
        inCard: field in value ? Boolean(value[field]?.inCard) : false,
        type: value[field]?.type,
        value: String(_value),
      };
    }
    const newUserData = { ...value, [field]: userdata };
    onChange(newUserData);
    handleSaveUserdata(newUserData, true);
  };

  return (
    <Box maxWidth={"100%"}>
      {canManage && (
        <Button
          id="addfield-button"
          endIcon={<AddCircleRounded />}
          variant="text"
          size="small"
          onClick={() => setOpen(true)}
        >
          {getTranslation(translations, "pm.mayactivities.addfield.label")}
        </Button>
      )}
      <Stack direction="column" maxWidth={"100%"} spacing={2}>
        <Accordion sx={{ maxWidth: "100%" }} elevation={0} disableGutters>
          <AccordionSummary expandIcon={<ArrowDropDown />}>
            <Typography variant="h6">
              {getTranslation(translations, "process_model.schema.title")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ maxWidth: "100%" }}>
            <Grid container maxWidth={"100%"} spacing={2}>
              {userSchema.map(({ id, name, type }) => (
                <Grid
                  key={`field-${id}`}
                  item
                  container
                  maxWidth={"100%"}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack flex={1} maxWidth={"100%"}>
                    <Stack flex={1} maxWidth={"100%"} direction="row">
                      {(value[name]?.value !== undefined || value[name]?.value !== "" ||
                        enableUndefined) && (
                          <React.Fragment>
                            <Box maxWidth="100%" flex={1} minWidth={0}>
                              <Userfield
                                linkChange={linkChange}
                                value={value[name]?.value}
                                onChange={(newVal) =>
                                  onChangeSchemaValues(name, newVal)
                                }
                                name={name}
                                type={type}
                                readonly={readonly}
                              />
                            </Box>
                            <Tooltip
                              title={getTranslation(
                                translations,
                                "start_process.userschema.cannot_delete"
                              )}
                            >
                              <span>
                                <IconButton disabled>
                                  <DeleteForeverRounded />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <Tooltip
                              title={getTranslation(
                                translations,
                                "generic.showingincard.caption"
                              )}
                              placement="left"
                            >
                              <Checkbox
                                edge="end"
                                disabled={readonly || loading}
                                // || !value[name]?.value
                                checked={Boolean(value[name]?.inCard)}
                                onChange={(_e, newChecked) =>
                                  onChangeInCardSchema(newChecked, name)
                                }
                                id={name}
                              />
                            </Tooltip>
                          </React.Fragment>)}
                    </Stack>
                  </Stack>
                </Grid>
              ))}
            </Grid>
            <Stack spacing={1} pr={2}>
              {Object.entries(value)
                .filter(([k, v]) => !userSchema.map((_) => _.name).includes(k))
                .map(([key, _value]) => (
                  <FieldsListItem
                    canManage={canManage}
                    onChangeInCard={onChangeInCard}
                    check={_value.inCard}
                    selectedProcess={selectedProcess}
                    key={`fields-list-item-${key}`}
                    item={{ key, value: _value }}
                    onDelete={() => onDelete(key)}
                    onEdit={(key, value) => onChangeSchemaValues(key, value)}
                    //corretto il readonly
                    readonly={readonly}
                  />
                ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Stack>
      {/* <Button id="save-userdata-btn" size="small" variant="contained" color="primary" onClick={handleSaveUserdata}>
        Salva Userdata (n.t)
      </Button> */}

      <Dialog open={open} onClose={() => setOpen(false)}>
        <form>
          <DialogHeader onClose={() => setOpen(false)}>
            {getTranslation(translations, "pm.mayactivities.addfield.label")}
          </DialogHeader>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="key-field"
                  size="small"
                  label={getTranslation(
                    translations,
                    "pm.mayactivities.key.label"
                  )}
                  fullWidth
                  error={Boolean(touched.key && errors.key)}
                  helperText={touched.key && errors.key}
                  value={values.key}
                  onChange={({ target }) => changeHandler("key", target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Userfield
                  name={values.key}
                  linkChange
                  onChange={(v) => changeHandler("value", v)}
                  type={values.type}
                  value={
                    values.type === SupportedUserDataTypes.boolean
                      ? Boolean(values.value)
                      : values.value
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  select
                  id="type-field"
                  size="small"
                  label={getTranslation(translations, "placeholder.type.label")}
                  fullWidth
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type}
                  value={values.type}
                  onChange={({ target }) => changeHandler("type", target.value)}
                >
                  {Object.entries(SupportedUserDataTypes)
                    .filter(([v]) => isNaN(Number(v)))
                    .map(([key, value]) => (
                      <MenuItem key={key} value={value}>
                        {getTranslation(
                          translations,
                          `supported.userdata.type.${key}`
                        )}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button size="small" onClick={() => setOpen(false)}>
              {getTranslation(
                translations,
                "pm.startprocess.button.cancel.caption"
              )}
            </Button>
            <Button
              id="confirm-field-button"
              variant="contained"
              size="small"
              type="submit"
              disabled={!isValid}
              onClick={handleSubmit}
            >
              {getTranslation(
                translations,
                "pm.mayactivities.confirmation.label"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default FieldsList;
