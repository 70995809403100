import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { SupportedUserDataTypes } from "hooks/useUserDataKeys";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

interface UserfieldProps {
  name: string;
  value: unknown;
  onChange: (v: unknown) => void;
  type: SupportedUserDataTypes;
  readonly?: boolean;
  linkChange?: boolean;
}
const DATETIME_FORMAT = "DD-MM-YYYY hh:mm";
const Userfield: React.FC<UserfieldProps> = ({
  readonly,
  value: _value,
  linkChange = false,
  onChange,
  name,
  type,
}) => {
  const value = _value === "false" ? false : _value === "true" ? true : _value;
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value || "");
  }, [value]);

  if (type === SupportedUserDataTypes.string) {
    return (
      <TextField
        fullWidth
        label={name}
        //con default non cambiava, ma ora abbiamo il problema dell'uncontrolled
        value={(linkChange ? value : internalValue) || ""}
        onChange={(e) =>
          linkChange
            ? onChange(e.target.value)
            : setInternalValue(e.target.value)
        }
        onBlur={() => linkChange || onChange(internalValue)}
        onKeyUp={(e) =>
          linkChange || (e.key === "Enter" && onChange(internalValue))
        }
        size="small"
        disabled={readonly}
        id={`string-userschema-${name}`}
      // InputLabelProps={{ shrink: Boolean(value) }}
      />
    );
  } else if (type === SupportedUserDataTypes.number) {
    return (
      <TextField
        onChange={(e) => {
          linkChange
            ? onChange(e.target.value)
            : setInternalValue(e.target.value);
        }}
        fullWidth
        label={name}
        //con default non cambiava, ma ora abbiamo il problema dell'uncontrolled
        value={(linkChange ? value : internalValue) || 0}
        onBlur={(e) => linkChange || onChange(internalValue)}
        onKeyUp={(e) =>
          linkChange || (e.key === "Enter" && onChange(internalValue))
        }
        size="small"
        type="number"
        disabled={readonly}
        id={`number-userschema-${name}`}
      // InputLabelProps={{ shrink: true }}
      />
    );
  } else if (type === SupportedUserDataTypes.boolean) {
    return (
      <FormControlLabel
        // sx={{ marginLeft: "0px", marginRight: "0px" }}
        control={
          <Checkbox
            // style={{ padding: "0px" }}
            size="small"
            checked={Boolean(value)}
            onChange={(e, checked) => {
              onChange(checked);
              e.stopPropagation();
            }}
            disabled={readonly}
            id={`boolean-userschema-${name}`}
          />
        }
        slotProps={{
          typography: {
            sx: {
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
          },
        }}
        sx={{ maxWidth: "100%", ml: 0, flex: 0 }}
        labelPlacement="start"
        label={name}
      />
    );
  } else if (type === SupportedUserDataTypes.date) {
    return (
      <DateTimePicker
        value={value ? dayjs(value as string, DATETIME_FORMAT) : null}
        onChange={(value: dayjs.Dayjs | null) =>
          onChange(value?.format(DATETIME_FORMAT))
        }
        ampm={false}
        slotProps={{
          textField: { size: "small", fullWidth: true, label: name, id: `date-userschema-${name}` },
        }}
        disabled={readonly}
      />
    );
  } else {
    return (
      <TextField
        fullWidth
        label={name}
        //con default non cambiava, ma ora abbiamo il problema dell'uncontrolled
        value={(linkChange ? value : internalValue) || ""}
        onChange={(e) =>
          linkChange
            ? onChange(e.target.value)
            : setInternalValue(e.target.value)
        }
        onBlur={() => linkChange || onChange(internalValue)}
        onKeyUp={(e) =>
          linkChange || (e.key === "Enter" && onChange(internalValue))
        }
        size="small"
        disabled={readonly}
        id={`string-userschema-${name}`}
      // InputLabelProps={{ shrink: Boolean(value) }}
      />
    )
  }
};

export default Userfield;
