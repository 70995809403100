import React, { useCallback, useEffect, useState } from "react";
import { Close, ArrowUpward, ArrowDownward } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItemButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  baseApi,
  flownodesApi,
  processesApi,
  organizationsApi,
  tagsApi,
} from "api";
import dayjs from "dayjs";
import { getTranslation } from "common";
import { getDefaultValueForType, getUserSchema, toBase64 } from "common/utilities";
import { PrioritiesDropDown } from "./PrioritiesDropDown";
import {
  useBreakpoint,
  useForm,
  useSelectedOrganization,
  useSnackbar,
  useTranslations,
} from "hooks";
import { isRequired, Validation } from "hooks/useForm";
import { InputComment, FieldsList } from "components";
import { Priority } from "hooks/usePriorities";
import DropZone from "../DropZone";
import noModel from "../../image/empty-pana.svg";
import { useNavigate } from "react-router-dom";
import LabelManager from "../labels/LabelManager";
import { LabelData } from "components/labels/label_Interface";
import Labels from "components/labels/Labels";
import { SortState } from "hooks/useSortableList";
import { UserData } from "hooks/useUserDataKeys";

const INITIAL_STATE = {
  name: "",
  meta: {},
  comment: "",
  selectedProcess: null,
};
interface Process {
  assignmentmode: number;
  id: number;
  idorganization: number;
  idprocessmodel: number;
  idswimlane: number;
  idtype: number;
  name: string;
  processmodelname: string;
  swimlanename: string;
  userschema: string;
}

interface StartProcessProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isAdmin?: boolean;
}

const StartProcess: React.FC<StartProcessProps> = ({
  open,
  setOpen,
  isAdmin = false,
  // refresh,
}) => {
  const selectedOrganization = useSelectedOrganization();
  const [processes, setProcesses] = useState<Process[]>([]);
  const [selectedProcess, setSelectedProcess] = useState<Process | null>(null);
  const [allPriorities, setAllPriorities] = useState<Priority[]>([]);
  const [checked, setChecked] = useState<LabelData[]>([]);
  const [selectedPriority, setSelectedPriority] = useState<Priority | null>(
    null
  );
  const administrator = true;

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const handleFileChange = (files: File[] | null) => {
    if (files) {
      setSelectedFiles(files);
    }
  };

  const [isLabelManagerShown, setIsLabelManagerShown] =
    useState<HTMLElement | null>(null);
  const handleLabelManagerClose = () => {
    setIsLabelManagerShown(null);
  };

  const { sendSnack } = useSnackbar();
  const translations = useTranslations();
  const validations: Validation[] = [
    ({ name }) =>
      isRequired(name) || {
        name: getTranslation(translations, "register.fieldrequired"),
      },
  ];

  const { changeHandler, reset, values, errors, touched, isValid } = useForm(
    INITIAL_STATE,
    validations
  );
  const navigate = useNavigate();
  const isMobile = useBreakpoint("down", "sm");
  useEffect(() => {
    if (open && selectedProcess?.userschema) {
      const schema = getUserSchema(selectedProcess.userschema);
      const newMeta: { [key: string]: UserData } = {};
      for (const item of schema) {
        newMeta[item.name] = {
          inCard: item.inCard,
          type: item.type,
          value: String(getDefaultValueForType(item.type))
        };
      }
      changeHandler("meta", newMeta);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProcess?.userschema, open]);

  const [loading, setLoading] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<SortState>(SortState.Asc);

  const sortProcessesAsc = () => {
    const sorted = [...processes].sort((a, b) =>
      a.processmodelname.localeCompare(b.processmodelname)
    );
    setProcesses(sorted);
    setSortOrder(SortState.Asc);
  };

  const sortProcessesDesc = () => {
    const sorted = [...processes].sort((a, b) =>
      b.processmodelname.localeCompare(a.processmodelname)
    );
    setProcesses(sorted);
    setSortOrder(SortState.Desc);
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await baseApi.get(
          `startevents/organizations/${selectedOrganization?.id}`
        );
        const sortedProcesses = res.data.possiblestart.sort(
          (a: { processmodelname: string }, b: { processmodelname: any }) =>
            a.processmodelname.localeCompare(b.processmodelname)
        );
        setProcesses(sortedProcesses);
      } catch {
      } finally {
      }
    })();
  }, [selectedOrganization]);

  useEffect(() => {
    if (!open) {
      setChecked([]);
      reset();
      setSelectedFiles([]);
    }
  }, [processes, open, reset]);

  const fetchOrganizationPriorities = useCallback(async () => {
    if (!selectedOrganization?.id) {
      return;
    }

    const response = await organizationsApi.get(
      `/${selectedOrganization?.id}/priorities`
    );
    if (response && response.data && response.data.priorities) {
      const data: Priority[] = response.data.priorities;
      setAllPriorities(data);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    fetchOrganizationPriorities();
  }, [fetchOrganizationPriorities]);

  useEffect(() => {
    if (
      selectedOrganization?.id &&
      allPriorities.length > 0 &&
      !selectedPriority
    ) {
      const defaultPriority = allPriorities[0];
      if (defaultPriority) {
        setSelectedPriority(defaultPriority);
      }
    }
  }, [selectedOrganization, allPriorities, selectedPriority]);

  const handleStartProcess = async ({
    name,
    files,
    meta,
    comment,
  }: {
    name: string;
    meta: { [key: string]: UserData };
    comment: string;
    files: File[];
  }) => {
    setLoading(true);
    try {
      const payload = {
        idprocessmodel: selectedProcess?.idprocessmodel,
        idorganization: selectedOrganization?.id,
        idevent: selectedProcess?.id,
        startdate: dayjs().utc().toISOString(),
        enddate: null,
        description: name,
        userdata: meta,
      };

      const { data } = await processesApi.post("", payload);
      const processId = data.process.id;
      const labelPromises = checked.map((label) =>
        tagsApi.post(
          `/${selectedOrganization?.id}/processes/${processId}/tags/${label.id}`
        )
      );
      await Promise.all(labelPromises);
      const {
        previousprocesstoken: { id: processTokenId },
      } = data;

      const processtokenObject = data.processtoken;

      if (comment) {
        await flownodesApi.post(`${processTokenId}/comments`, {
          comment,
        });
      }

      if (selectedPriority && selectedPriority.id && processtokenObject) {
        const idprocess = processtokenObject.idprocess;
        const requestData = {};
        await flownodesApi.post(
          `/${idprocess}/priorities/${selectedPriority.id}`,
          requestData
        );
      }

      if (files) {
        let hasError = false;
        for (let file of files) {
          const payload = {
            idorganization: selectedOrganization?.id,
            filename: file.name,
            cloudlink: "",
            idtype: 1,
            description: file.name,
            blob: await toBase64(file),
          };
          try {
            await flownodesApi.post(`${processTokenId}/attachments`, payload);
          } catch {
            hasError = true;
          } finally {
          }
        }
        if (!hasError && files.length > 0) {
          sendSnack({
            type: "success",
            message: getTranslation(
              translations,
              "generic.fileuploadedsuccessfully"
            ),
          });
        }
      }
    } finally {
      reset();
      setSelectedProcess(null);
      setSelectedPriority(null);
      setOpen(false);
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={Boolean(open)}
      maxWidth={"lg"}
      fullWidth
      fullScreen={isMobile}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h6">
              {getTranslation(translations, "pm.startprocess.label.caption")}
            </Typography>
            <Labels labelCompleteMode labels={checked} />
          </Stack>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ overflow: "auto" }}>
        <Stack direction="row" spacing={1}>
          {processes.length > 0 && (
            <IconButton
              onClick={
                sortOrder === SortState.Asc
                  ? sortProcessesDesc
                  : sortProcessesAsc
              }
            >
              {sortOrder === SortState.Asc ? (
                <ArrowDownward />
              ) : (
                <ArrowUpward />
              )}
            </IconButton>
          )}
        </Stack>
        {processes.length === 0 ? (
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Box
              component="img"
              alt="..."
              sx={{
                width: "308px",
                height: "308px",
              }}
              src={noModel}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                color="textSecondary"
                component="p"
                sx={{ mt: 1, fontSize: "1.2rem", fontWeight: "bold" }}
              >
                {getTranslation(
                  translations,
                  "generic.create_one_model.caption"
                )}
              </Typography>
              {isAdmin && (
                <Button
                  onClick={() => navigate("/dashboard/process/insert")}
                  variant="outlined"
                  size="large"
                  sx={{ mt: 3 }}
                >
                  {getTranslation(translations, "pm.label.button.process_name")}
                </Button>
              )}
            </Box>
          </Stack>
        ) : (
          <Grid
            container
            // height="100%"
            // maxWidth={"100%"}
            // alignItems="flex-start"
            // justifyContent="flex-start"
            // direction={isMobile ? "column" : "row"}
            spacing={2}
          >
            <Grid item xs={12} md={2}>
              <Grid container>
                <List
                  id="process-list"
                  sx={{
                    paddingTop: "0",
                    width: isMobile ? "100%" : 500,
                    height: isMobile ? "auto" : 400,
                    overflow: "auto",
                  }}
                >
                  {processes.map((process) => (
                    <ListItemButton
                      key={process.id}
                      id={`startprocess-fields-${process.id}`}
                      selected={selectedProcess?.id === process.id}
                      sx={{ width: "100%", marginBottom: 1 }}
                      onClick={() => {
                        if (selectedProcess?.id !== process.id) {
                          setSelectedProcess(process);
                        }
                      }}
                    >
                      {process.name === ""
                        ? process.processmodelname
                        : process.processmodelname + " (" + process.name + ")"}
                    </ListItemButton>
                  ))}
                </List>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Stack
                    direction="column"
                    spacing={1}
                    sx={{ paddingTop: "8px" }}
                  >
                    <TextField
                      id="startprocess-name"
                      fullWidth
                      inputProps={{ maxLength: 7000 }}
                      variant="outlined"
                      size="small"
                      label={getTranslation(
                        translations,
                        "pm.processstart.name.label"
                      )}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      value={values.name}
                      onChange={({ target }) =>
                        changeHandler("name", target.value)
                      }
                    />
                    <Button
                      size="small"
                      variant="outlined"
                      component="label"
                      onClick={(e) => setIsLabelManagerShown(e.currentTarget)}
                    >
                      {getTranslation(translations, "generic.labels.caption")}
                    </Button>
                    {isLabelManagerShown && (
                      <LabelManager
                        checked={checked}
                        setChecked={setChecked}
                        //idprocess={task?.idprocess}
                        open={isLabelManagerShown}
                        handleClose={handleLabelManagerClose}
                      />
                    )}
                    <PrioritiesDropDown
                      allPriorities={allPriorities}
                      value={selectedPriority}
                      onChange={(newPriority) =>
                        setSelectedPriority(newPriority)
                      }
                      administrator={administrator}
                    />
                    <DropZone
                      files={selectedFiles}
                      onFileChange={handleFileChange}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <InputComment
                    onChange={(c) => changeHandler("comment", c)}
                    value={values.comment || ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldsList
                    linkChange
                    enableUndefined
                    handleSaveUserdata={async (newValues) =>
                      changeHandler("meta", newValues)
                    }
                    selectedProcess={selectedProcess?.id}
                    schema={selectedProcess?.userschema}
                    value={values.meta}
                    onChange={(newValues) => changeHandler("meta", newValues)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      {processes.length > 0 && (
        <DialogActions sx={{ paddingRight: "19px" }}>
          <Stack direction="row" spacing={2}>
            <Button
              id="cancel-button-process"
              color="primary"
              variant="outlined"
              size="small"
              onClick={() => setOpen(false)}
              sx={{
                color: "black",
                borderColor: (_) => _.palette.grey[200],
              }}
            >
              {getTranslation(
                translations,
                "pm.startprocess.button.cancel.caption"
              )}
            </Button>
            <Button
              id="startprocess-button2"
              variant="contained"
              color="primary"
              size="small"
              disabled={!isValid || !selectedProcess || loading}
              onClick={() =>
                handleStartProcess({ ...values, files: selectedFiles })
              }
            >
              {getTranslation(
                translations,
                "pm.startprocess.button.ok.caption"
              )}
            </Button>
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default StartProcess;
