import { styled } from "@mui/material";

export default styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  px: theme.spacing(2),
  gap: theme.spacing(2),
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
