import createContext, { Action } from "./createDataContext";
import { DispatchAction } from "./createDataContext";

const SET_ORGANIZATION_FIRST_SEARCH = "SET_ORGANIZATION_FIRST_SEARCH";
const REMOVE_ORGANIZATION_FIRST_SEARCH = "REMOVE_ORGANIZATION_FIRST_SEARCH";
const SET_SIDEBAR_OPEN = "SET_SIDEBAR_OPEN";
const SET_MODEL_CHOICE = "SET_MODEL_CHOICE";

const INITIAL_STATE = {
  organizationsFirstSearch: false,
  sidebarOpen: true,
  modelChoice: false
};

const reducer = (state: typeof INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case SET_ORGANIZATION_FIRST_SEARCH: {
      return { ...state, organizationsFirstSearch: true };
    }
    case REMOVE_ORGANIZATION_FIRST_SEARCH: {
      return { ...state, organizationsFirstSearch: false };
    }
    case SET_SIDEBAR_OPEN: {
      return { ...state, sidebarOpen: action.payload };
    }
    case SET_MODEL_CHOICE: {
      return { ...state, modelChoice: action.payload };
    }
    default: {
      return state;
    }
  }
};

const addOrganizationsFirstSearch: DispatchAction<{}, void> =
  (dispatch) => () => {
    dispatch({ type: SET_ORGANIZATION_FIRST_SEARCH });
  };
const removeOrganizationsFirstSearch: DispatchAction<{}, void> =
  (dispatch) => () => {
    dispatch({ type: REMOVE_ORGANIZATION_FIRST_SEARCH });
  };
const setSidebarOpen: DispatchAction<boolean, void> = (dispatch) => (open) => {
  dispatch({ type: SET_SIDEBAR_OPEN, payload: open });
};
const setModelChoice: DispatchAction<boolean, void> = (dispatch) => (enabled) => {
  dispatch({ type: SET_MODEL_CHOICE, payload: enabled });
};
const actions = {
  addOrganizationsFirstSearch,
  removeOrganizationsFirstSearch,
  setSidebarOpen,
  setModelChoice
  // ACTIONS
};
type ActionsType = typeof actions;
export type ContextType = {
  state: typeof INITIAL_STATE;
  actions: {
    [Property in keyof ActionsType]: ReturnType<ActionsType[Property]>;
  };
};
export const { Context, Provider } = createContext<
  ContextType,
  typeof INITIAL_STATE,
  typeof actions
>(reducer, actions, INITIAL_STATE);
