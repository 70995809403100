import { Task } from "model/activitiesModel";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useSSE from "./useSSE";
import { SelectedOrganizationType } from "model/organizationModel";
import { customSort, getUserData } from "common/utilities";
import { SupportedUserDataTypes } from "./useUserDataKeys";

type Tasks = { todo: Task[]; doing: Task[] };

const useActivities = (
  organization: SelectedOrganizationType | null
): [
  Tasks,
  React.Dispatch<React.SetStateAction<Tasks>>,
  React.MutableRefObject<{
    todo: number[];
    doing: number[];
  }>,
  boolean
  // ServerTag[]
] => {
  // const [tags, setTags] = useState<ServerTag[]>([]);
  const [tasks, setTasks] = useState<Tasks>({
    todo: [],
    doing: [],
  });
  const [loading, setLoading] = useState<boolean>(false);
  const orderedArray = useRef<{ todo: number[]; doing: number[] }>({
    todo: [],
    doing: [],
  });
  const setOrderedTasks = useCallback(
    (
      dataWaitingFlowNodes: Task[] | null = null,
      dataDoingFlowNodes: Task[] | null = null
    ) => {
      setTasks(({ doing: currentDoing, todo: currentTodo }) => {
        let todo = dataWaitingFlowNodes || [...currentTodo];
        let doing = dataDoingFlowNodes || [...currentDoing];
        doing.map((_) => ({
          ..._,
          sequenceflows: _.sequenceflows?.sort((a, b) =>
            customSort(a.name, b.name, SupportedUserDataTypes.string)
          ),
        }));
        if (dataWaitingFlowNodes && orderedArray.current.todo.length > 0) {
          todo = dataWaitingFlowNodes.sort(
            (a: Task, b: Task) =>
              -(
                orderedArray.current.todo.findIndex(
                  (_) => _ === b?.idprocesstoken
                ) -
                orderedArray.current.todo.findIndex(
                  (_) => _ === a.idprocesstoken
                )
              )
          );
        }
        if (dataDoingFlowNodes && orderedArray.current.doing.length > 0) {
          doing = dataDoingFlowNodes.sort(
            (a: Task, b: Task) =>
              -(
                orderedArray.current.doing.findIndex(
                  (_) => _ === b?.idprocesstoken
                ) -
                orderedArray.current.doing.findIndex(
                  (_) => _ === a.idprocesstoken
                )
              )
          );
        }
        setLoading(false);
        return {
          todo,
          doing,
        };
      });
    },
    []
  );

  const handleChangeTasks = useCallback(
    (data: { waitingflownodes: Task[]; doingflownodes: Task[] }) =>
      setOrderedTasks(
        data.waitingflownodes.map((task) => ({
          ...task,
          userdataLength: Object.values(getUserData(task.userdata)).filter(
            (v) => v.inCard
          ).length,
        })),
        data.doingflownodes.map((task) => ({
          ...task,
          userdataLength: Object.values(getUserData(task.userdata)).filter(
            (v) => v.inCard
          ).length,
        }))
      ),
    [setOrderedTasks]
  );

  const { resetLastEventID: resetEventTasks, mounted } = useSSE<{
    waitingflownodes: Task[];
    doingflownodes: Task[];
  }>(`tasks/canget/sse/${organization?.id}`, handleChangeTasks, true);

  useEffect(() => {
    if (organization?.id && mounted) {
      resetEventTasks();
      setLoading(true);
    }
  }, [organization?.id, resetEventTasks, mounted]);

  return [tasks, setTasks, orderedArray, loading];
};

export default useActivities;
