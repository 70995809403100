import { organizationsApi } from "api";
import { useCallback, useEffect, useState } from "react";
export interface Monitor {
  id: number;
  starttime: string;
  processmodel_name: string;
  idprocessmodel: number;
  idevent: number;
  enabled: boolean;
  idtype: number;
  idprocesstoken: number;
}
const useMonitor = (idOrganization: number | null | undefined) => {
  const [monitors, setMonitors] = useState<Monitor[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getMonitors: () => Promise<void> = useCallback(async () => {
    setIsLoading(true)
    try {
      const { data } = await organizationsApi.get(
        `${idOrganization}/timercontroller`
      );
      setMonitors(data.timer);
    } catch {
    } finally {
      setIsLoading(false)
    }
  }, [idOrganization]);

  useEffect(() => {
    getMonitors();
  }, [getMonitors]);

  const deleteTimerStartEvent = async (timer: Monitor) => {
    try {
      await organizationsApi.delete(
        `${idOrganization}/timercontroller/${timer.id}?all=true`
      );
    } catch {
    } finally {
      getMonitors();
    }
  };
  const stopNextTimerStartEvent = async (timer: Monitor) => {
    try {
      await organizationsApi.delete(
        `${idOrganization}/timercontroller/${timer.id}`
      );
    } catch {
    } finally {
      getMonitors();
    }
  };
  const switchEnableTimerStartEvent = async (timer: Monitor) => {
    try {
      await organizationsApi[timer.enabled ? "delete" : "post"](
        `${idOrganization}/timercontroller/${timer.id}/enabled`
      );
    } catch {
    } finally {
      getMonitors();
    }
  };

  return { monitors, deleteTimerStartEvent, getMonitors, stopNextTimerStartEvent, switchEnableTimerStartEvent, isLoading };
};

export default useMonitor;
