import React, { useCallback, useContext, useEffect, useState } from "react";
import { getTranslation } from "common";
import FlowentiLogo from "../../../image/Logo.svg"; //# use this cause is without background
import {
  // Email as EmailIcon,
  // DataThresholding as DataThresholdingIcon,
  DashboardCustomize,
  QueryStats,
  Notifications as NotificationsIcon,
  KeyboardDoubleArrowRightRounded,
  KeyboardDoubleArrowLeftRounded,
  // KeyboardArrowDownRounded,
  PersonRounded,
  LockRounded,
  // SettingsRounded,
  // InfoRounded,
  LogoutRounded,
  MonitorRounded,
  ListAltRounded,
  GroupWorkRounded,
  PrecisionManufacturing,
  AutoStoriesOutlined,
  BrowseGalleryOutlined,
  AutoAwesome,
  Translate,
} from "@mui/icons-material";
import SearchBar from "../../../components/SearchBar";
import {
  Box,
  IconButton,
  List,
  Stack,
  Typography,
  Paper,
  Menu,
  MenuItem,
  Divider,
  Button,
  Tooltip,
} from "@mui/material";
import SideBarItem, {
  SideBarItemProps,
} from "./components/SideBarItem/SideBarItem";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Sidebar, SidebarHeader } from "./components/Sidebar";
import {
  useLLM,
  // useBreakpoint,
  useOrganizations,
  useSidebar,
  useTranslations,
  useUser,
} from "hooks";
import {
  SelectedOrganization,
  PasswordDialog,
  RefreshApplication,
} from "components";
import { HEADER_HEIGHT } from "common/theme";
import { removeMessage } from "Redux/messageReducer";
import { AuthContext, UtilityContext } from "contexts";
import AvatarRem from "components/AvatarRem";
import AiDrawer from "components/aiChat/AiDrawer";
import useKonamiCode from "hooks/useKonamiCode";

const SELECT_ORGANIZATIONS_ROUTES = [
  "process",
  "myactivities",
  "processes",
  "schedules",
  "statistics",
];
const DISABLE_GUIDE_ROUTES = ["process/edit", "robots", "process/insert"];
const GLOBAL_SEARCH_ROUTES = ["organizations"];
interface DashboardProps {
  getVersion: (id: number | null | undefined) => number;
  newestVersion: string;
}

const Dashboard: React.FC<DashboardProps> = ({ getVersion, newestVersion }) => {
  // const isMobile = useBreakpoint("down", "md");
  const [userMenu, setUserMenu] = useState<HTMLElement | null>(null);
  const user = useUser();
  const {
    actions: { logout },
  } = useContext(AuthContext);
  const {
    actions: { setModelChoice },
  } = useContext(UtilityContext);
  const translations = useTranslations();
  const { pathname } = useLocation();
  const [openSecurityMenu, setopenSecurityMenu] = useState<boolean>(false);
  const routeName = pathname.split("dashboard/")[1].split("/")[0];
  const showSelectOrganizations: boolean =
    SELECT_ORGANIZATIONS_ROUTES.includes(routeName);
  const showGlobalSearch: boolean = GLOBAL_SEARCH_ROUTES.includes(routeName);
  const dispatch = useDispatch();
  const [firstFilter, setFirstFilter] = useState<string>("");
  const [secondFilter, setSecondFilter] = useState<string>("");
  const [shouldShowSideBar, setShouldShowSideBar] = useSidebar();

  useOrganizations();

  const handleLogout = useCallback(() => {
    dispatch(removeMessage());
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("logout", handleLogout);
    return () => window.removeEventListener("logout", handleLogout);
  }, [handleLogout]);

  const sideBarItems: SideBarItemProps[] = [
    {
      logoSrc: <GroupWorkRounded />,
      label: getTranslation(translations, "menu.left.organizations"),
      path: "organizations",
    },
    {
      logoSrc: <ListAltRounded />,
      label: getTranslation(translations, "menu.left.myactivities"),
      path: "myactivities",
    },
    {
      logoSrc: <MonitorRounded />,
      label: getTranslation(translations, "menu.left.monitor"),
      path: "processes",
      children: [
        {
          logoSrc: <AutoStoriesOutlined />,
          // logoSrc: <PlayCircleOutline />,
          label: getTranslation(translations, "menu.left.activities"),
          path: "processes",
        },
        {
          logoSrc: <BrowseGalleryOutlined />,
          label: getTranslation(translations, "menu.left.timer"),
          path: "schedules",
        },
      ],
    },
    {
      logoSrc: <DashboardCustomize />,
      label: getTranslation(translations, "menu.left.process"),
      path: "process",
    },
    {
      logoSrc: <QueryStats />,
      label: getTranslation(translations, "menu.left.statistics"),
      path: "statistics",
      // disabled: true,
    },
    {
      logoSrc: <PrecisionManufacturing />,
      label: getTranslation(translations, "my.robots.name"),
      // getTranslation(translations, "menu.left.notifications"),
      path: "robots",
      // disabled: true,
    },
    {
      logoSrc: <NotificationsIcon />,
      label: getTranslation(translations, "menu.left.notifications"),
      path: "notifications",
      disabled: true,
    },
  ];

  // const icons = [NotificationsIcon, EmailIcon, DataThresholdingIcon];
  const menuItemsUser = [
    {
      name: getTranslation(translations, "account.profile.name"),
      action: () => {
        setUserMenu(null);
      },
      link: "/dashboard/profile",
      icon: <PersonRounded />,
      path: "profile",
    },
    {
      name: getTranslation(translations, "account.security.name"),
      action: () => {
        setopenSecurityMenu((_) => !_);
        setUserMenu(null);
      },
      icon: <LockRounded />,
      path: "security",
    },
    //hidden until they actually do something
    // {
    //   name: getTranslation(translations, "account.settings.name"),
    //   action: () => {},
    //   icon: <SettingsRounded />,
    //   path: "settings",
    // },
    // {
    //   name: getTranslation(translations, "account.help.name"),
    //   action: () => {},
    //   icon: <InfoRounded />,
    //   path: "help",
    // },
    {
      name: getTranslation(translations, "account.logout.name"),
      action: handleLogout,
      icon: <LogoutRounded />,
      path: "logout",
    },
  ];

  const {
    aiAnswers,
    handleSubmit,
    loading,
    llmInputRef,
    modelInputRef,
    openAI,
    setOpenAI,
  } = useLLM("guide", true, true);

  const handleSubmitLLM = async () => {
    await handleSubmit({});
  };

  useKonamiCode(
    ["f", "l", "o", "w", "e", "n", "t", "i"],
    () => {
      setModelChoice(true);
    },
    { caseInsensitive: true, matchMode: "key" }
  );

  useEffect(() => {
    if (DISABLE_GUIDE_ROUTES.some((a) => pathname.includes(a))) {
      setOpenAI(false);
    }
  }, [pathname, setOpenAI]);
  
  return (
    <React.Fragment>
      <AiDrawer
        variant="persistent"
        modelInputRef={modelInputRef}
        llmInputRef={llmInputRef}
        LLMLoading={loading}
        aiAnswers={aiAnswers}
        handleSubmitLLM={handleSubmitLLM}
        openAI={openAI}
        setOpenAI={setOpenAI}
      />
      <Stack direction="row">
        <Sidebar variant="permanent" open={shouldShowSideBar}>
          <SidebarHeader
            sx={{
              backgroundColor: (_) => _.palette.grey[200],
              boxShadow: "none",
              p: 0,
              pr: 3,
            }}
          >
            <>
              <Box
                component="img"
                alt="..."
                style={{
                  width: "48px",
                  height: "48px",
                  marginLeft: "4px",
                }}
                src={FlowentiLogo}
              />
              {shouldShowSideBar && (
                <Typography variant="h6">Flowenti</Typography>
              )}
            </>
          </SidebarHeader>
          <Stack
            sx={{
              flex: 1,
              backgroundColor: (_) => _.palette.grey[200],
            }}
            spacing={2}
          >
            <List
              id="menu-list"
              sx={{
                pt: "20px",
                boxShadow: "none",
                flex: 1,
              }}
            >
              <Box>
                <Tooltip
                  placement="right"
                  title={
                    shouldShowSideBar
                      ? getTranslation(
                          translations,
                          "generic.menu_reduce.caption"
                        )
                      : getTranslation(
                          translations,
                          "generic.open_menu.caption"
                        )
                  }
                >
                  <IconButton
                    sx={{
                      transition: "margin 0.3s",
                      ml: shouldShowSideBar ? "19px" : "9px",
                      mb: 3,
                      color: "rgba(84, 189, 193, 1)",
                      borderRadius: "6px",
                    }}
                    id="organization-icon"
                    onClick={() => setShouldShowSideBar(!shouldShowSideBar)}
                  >
                    {shouldShowSideBar ? (
                      <KeyboardDoubleArrowLeftRounded />
                    ) : (
                      <KeyboardDoubleArrowRightRounded />
                    )}
                  </IconButton>
                </Tooltip>
                {sideBarItems.map((sideBarItem, index) => (
                  <SideBarItem
                    key={sideBarItem.label}
                    logoSrc={sideBarItem.logoSrc}
                    label={sideBarItem.label}
                    children={sideBarItem.children}
                    path={sideBarItem.path}
                    shouldShowLabel={shouldShowSideBar}
                    disabled={sideBarItem.disabled}
                  />
                ))}
              </Box>
            </List>
            <Box
              mt={35}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
                py: 2,
                flex: 1,
              }}
            >
              <Divider sx={{ mb: 2, width: "78%" }} />
              {shouldShowSideBar ? (
                <Button
                  variant="outlined"
                  startIcon={<LogoutRounded />}
                  onClick={handleLogout}
                >
                  {getTranslation(translations, "account.logout.name")}
                </Button>
              ) : (
                <IconButton
                  sx={{
                    color: "rgba(84, 189, 193, 1)",
                    borderRadius: "6px",
                    // backgroundColor: "rgba(84, 189, 193, 0.3)",
                    // "&:hover": {
                    //   backgroundColor: "rgba(84, 189, 193, 0.4)",
                    // },
                  }}
                  onClick={handleLogout}
                >
                  <LogoutRounded />
                </IconButton>
              )}
            </Box>
          </Stack>
        </Sidebar>
        <Stack flex={1}>
          <Stack
            component={Paper}
            direction="row"
            justifyContent={{ xs: "center", lg: "space-between" }}
            sx={{
              backgroundColor: (_) => _.palette.grey[200],
              boxShadow: "none",
              px: (_) => _.spacing(2),
              borderRadius: 0,
              height: HEADER_HEIGHT,
            }}
          >
            <Box
              sx={{
                display: { xs: "flex", lg: "flex", width: "100%" },
                alignItems: "center",
              }}
            >
              {showGlobalSearch && (
                <Box sx={{ display: "flex", flexGrow: 0 }}>
                  <SearchBar
                    id="search-bar-dash"
                    placeholder={getTranslation(
                      translations,
                      "pm.organizationchart.ricerca.placeholder"
                    )}
                    setFilter={(text: string) => {
                      setFirstFilter(text);
                      setSecondFilter(text);
                    }}
                  />
                </Box>
              )}
              <Box
                flexGrow={1}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <RefreshApplication newestVersion={newestVersion} />
                {showSelectOrganizations && <SelectedOrganization />}
              </Box>
            </Box>
            <Stack
              justifyContent="flex-end"
              // flex={1}
              direction="row"
              spacing={2}
              alignItems="center"
            >
              {!DISABLE_GUIDE_ROUTES.some((a) => pathname.includes(a)) && (
                <Tooltip
                  title={getTranslation(translations, "ai_helper.tooltip")}
                >
                  <IconButton onClick={() => setOpenAI(true)}>
                    <AutoAwesome />
                  </IconButton>
                </Tooltip>
              )}
              {/* <Stack
                sx={{ display: { lg: "flex" } }}
                direction="row"
                spacing={2}
                alignItems="center"
              > */}
              {/*
                    {icons.map((Icon, index) => (
                <IconButton
                  key={index}
                  sx={{
                    borderRadius: "6px",
                    backgroundColor: "rgba(84, 189, 193, 0.3)",
                    "&:hover": {
                      backgroundColor: "rgba(84, 189, 193, 0.4)",
                    },
                  }}
                >
                    <Icon sx={{ color: "rgba(84, 189, 193, 1)" }} />
                </IconButton>
              ))}
                     */}
              {/* </Stack> */}
              <IconButton
                id="profile-menu"
                key={getVersion(1)}
                sx={{ alignSelf: "flex-end" }}
                onClick={({ currentTarget }) => setUserMenu(currentTarget)}
              >
                {/* <Stack direction="row" alignItems="center" spacing={1}> */}
                <AvatarRem icon={user?.picture || ""}>
                  {user?.firstname[0]}
                  {user?.lastname[0]}
                </AvatarRem>
                {/* <KeyboardArrowDownRounded
                  sx={{
                    transform: userMenu ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                /> */}
                {/* </Stack> */}
              </IconButton>
              <Menu
                disableScrollLock
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                onClose={() => setUserMenu(null)}
                MenuListProps={{
                  sx: { pt: 0 },
                }}
                open={Boolean(userMenu)}
                anchorEl={userMenu}
              >
                <MenuItem
                  sx={{
                    backgroundColor: (_) =>
                      _.palette.mode === "dark"
                        ? "rgba(255,255,255,0.16)"
                        : "rgba(0,0,0,0.16)",
                    fontWeight: "bold",
                    pointerEvents: "none",
                  }}
                >
                  {getTranslation(translations, "account.name")}
                </MenuItem>
                <Divider sx={{ mt: "0!important" }} />
                <MenuItem onClick={() => {}} disableRipple>
                  <Stack spacing={1} direction="row" alignItems="center">
                    <AvatarRem icon={user?.picture || ""}>
                      {user?.firstname[0]}
                      {user?.lastname[0]}
                    </AvatarRem>
                    <Stack spacing={1}>
                      <span style={{ fontWeight: "bold" }}>
                        {user?.firstname} {user?.lastname}
                      </span>
                      <span>{user?.email}</span>
                    </Stack>
                  </Stack>
                </MenuItem>
                <Divider sx={{ width: "87%", mx: "auto" }} />
                {menuItemsUser
                  .filter(
                    (item) =>
                      !(
                        item.path === "security" &&
                        user?.authenticationtype === 1
                      )
                  )
                  .map((item, index) => (
                    <Link
                      key={item.name}
                      style={{ color: "inherit", textDecoration: "none" }}
                      to={item.link ? item.link : "#"}
                    >
                      <MenuItem id={`${item.path}-menu`} onClick={item.action}>
                        <Box
                          sx={{
                            color: "rgba(84, 189, 193, 1)",
                            borderRadius: "6px",
                            marginRight: "8px",
                            //   backgroundColor: "rgba(84, 189, 193, 0.3)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "4px",
                            // "&:hover": {
                            //   backgroundColor: "rgba(84, 189, 193, 0.4)",
                            // },
                          }}
                        >
                          {item.icon}
                        </Box>
                        {item.name}
                      </MenuItem>
                    </Link>
                    // <React.Fragment key={index}>
                    //   {index === menuItemsUser.length - 2 && (
                    //     <Divider sx={{ width: "70%", mx: "auto", mt: 5, mb: 2 }} />
                    //   )}
                    // </React.Fragment>
                  ))}
              </Menu>
            </Stack>
          </Stack>
          <PasswordDialog
            open={openSecurityMenu}
            setOpen={setopenSecurityMenu}
            onClose={() => setopenSecurityMenu(false)}
            uID={user?.userId}
          />
          <Box
            sx={{
              overflowX: "hidden",
              display: "flex",
              flexDirection: "column",
              maxWidth: `calc(100vw - ${shouldShowSideBar ? 198 : 64}px)`,
              height: "calc(100vh - 61px)",
              minHeight: "calc(100vh - 61px)",
              maxHeight: "calc(100vh - 61px)",
              // marginLeft: 3,
              px: 2,
              pt: 2,
            }}
          >
            {/* <Typography
            flexShrink={0}
            sx={{
              mt: ".5em",
              mb: "0.4em",
              display: { xs: "none", md: "flex" },
            }}
            variant={isMobile ? "h6" : "h5"}
          >
            {getTranslation(translations, `menu.left.${routeName}`)}
          </Typography> */}

            <Outlet
              context={{
                firstFilter,
                setFirstFilter,
                secondFilter,
                setSecondFilter,
              }}
            />
          </Box>
        </Stack>
      </Stack>
    </React.Fragment>
  );
};

export default Dashboard;
