import * as React from "react";
import {
  Badge,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  touchRippleClasses,
} from "@mui/material";
import {
  Add,
  ChevronLeft,
  ChevronRight,
  Folder,
  FolderOpen,
  Remove,
} from "@mui/icons-material";
import { ExtendedTask } from "model/statisticsModel";
import { getTranslation } from "common";
import { useTranslations } from "hooks";

const CustomTable: React.FunctionComponent<{
  formatDateShort: (value: Date, includeTime?: boolean) => string;
  rowHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  locale: string;
  tasks: ExtendedTask[];
  selectedTaskId: string;
  setSelectedTask: (taskId: string) => void;
  onExpanderClick: (task: ExtendedTask) => void;
  onRowClick?: (task: ExtendedTask) => void;
}> = ({
  formatDateShort,
  rowHeight,
  rowWidth,
  tasks,
  fontFamily,
  fontSize,
  locale,
  selectedTaskId,
  setSelectedTask,
  onExpanderClick,
  onRowClick,
}) => {
    const [open, setOpen] = React.useState(true);
    const translations = useTranslations();
    return (
      <Badge
        sx={{
          display: "flex",
        }}
        slotProps={{
          badge: {
            style: {
              cursor: "pointer",
              fontSize: "24px",
              transform: "translate(-10%, 50%)",
              width: 30,
              height: 30,
              borderRadius: "50%",
            },
            onClick: () => setOpen((_) => !_),
          },
        }}
        variant="standard"
        color="primary"
        classes={touchRippleClasses}
        badgeContent={
          <Tooltip title={open ? getTranslation(translations, "generic.hide.caption") : getTranslation(translations, "generic.show.caption")}>
            {open ? (
              <ChevronLeft fontSize={"24px" as "small"} />
            ) : (
              <ChevronRight fontSize={"24px" as "small"} />
            )}
          </Tooltip>
        }
      >
        <Collapse collapsedSize={35} orientation="horizontal" in={open}>
          <Table
            size="small"
            className="Gantt-Task-List_Wrapper"
            style={{
              opacity: open ? 1 : 0,
              pointerEvents: open ? "auto" : "none",
              fontFamily: fontFamily,
              fontSize: fontSize,
            }}
          >
            <TableHead>
              <TableRow style={{ height: rowHeight }}>
                <TableCell>
                  <Typography sx={{ fontWeight: "bold" }}> {getTranslation(translations, "register.registration.name.label")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontWeight: "bold" }}>{getTranslation(translations, "generic.from.caption")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontWeight: "bold" }}>{getTranslation(translations, "generic.to.caption")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontWeight: "bold" }}>{getTranslation(translations, "generic.user")}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((t) => {
                return (
                  <TableRow
                    className="Gantt-Task-List_Row"
                    style={{ height: rowHeight }}
                    key={`${t.id}row`}
                    onClick={() => {
                      if (onRowClick) {
                        onRowClick(t);
                      }
                      if (selectedTaskId === t.id) {
                        setSelectedTask("");
                      } else {
                        setSelectedTask(t.id);
                      }
                    }}
                  >
                    {/**
                   * Name
                   */}
                    <Tooltip title={t.name}>
                      <TableCell
                        className="Gantt-Task-List_Cell"
                        sx={{
                          p: 0,
                          minWidth: rowWidth,
                          maxWidth: rowWidth,
                          maxHeight: rowHeight,
                          minHeight: rowHeight,
                          overflow: "hidden",
                        }}
                      >
                        <Stack
                          direction="row"
                          sx={{
                            pl:
                              t.taskType === "processmodel"
                                ? 0
                                : t.taskType === "process"
                                  ? 1
                                  : 5,
                            //   minWidth: rowWidth,
                            //   maxWidth: rowWidth,
                            maxHeight: rowHeight,
                            minHeight: rowHeight,
                            overflow: "hidden",
                          }}
                          // justifyContent="space-between"
                          alignItems="center"
                        >
                          {t.type === "project" && (
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                onExpanderClick(t);
                                e.stopPropagation();
                              }}
                            >
                              {t.hideChildren ? (
                                <React.Fragment>
                                  {t.taskType === "processmodel" ? (
                                    <Folder fontSize="small" />
                                  ) : (
                                    <Add fontSize="small" />
                                  )}
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  {t.taskType === "processmodel" ? (
                                    <FolderOpen fontSize="small" />
                                  ) : (
                                    <Remove fontSize="small" />
                                  )}
                                </React.Fragment>
                              )}
                            </IconButton>
                          )}
                          <Typography
                            sx={{
                              textDecoration: t.taskType === "activity" ? "underline" : "none",
                              cursor: t.taskType === "activity" ? "pointer" : "default",
                              color: t.taskType === "activity" ? "primary.main" : "inherit",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                            className="Gantt-Task-List_Cell__Link"
                          >
                            {t.name}
                          </Typography>
                        </Stack>
                      </TableCell>
                    </Tooltip>
                    {/**
                   * Start Time
                   */}
                    <Tooltip title={formatDateShort(t.start, true)}>
                      <TableCell
                        className="Gantt-Task-List_Cell"
                        style={{
                          minWidth: rowWidth,
                          maxWidth: rowWidth,
                        }}
                      //   title={formatDateShort(t.start, includeTime)}
                      >
                        &nbsp;{formatDateShort(t.start, false)}
                      </TableCell>
                    </Tooltip>
                    {/**
                   * End Time
                   */}
                    <Tooltip title={formatDateShort(t.end, true)}>
                      <TableCell
                        className="Gantt-Task-List_Cell"
                        style={{
                          minWidth: rowWidth,
                          maxWidth: rowWidth,
                        }}
                      >
                        &nbsp;{formatDateShort(t.end, false)}
                      </TableCell>
                    </Tooltip>
                    {/**
                   * User full name
                   */}
                    <Tooltip title={t.userfullname}>
                      <TableCell
                        className="Gantt-Task-List_Cell"
                        style={{
                          minWidth: rowWidth,
                          maxWidth: rowWidth,
                        }}
                      //   title={formatDateShort(t.end, includeTime)}
                      >
                        &nbsp;{t.userfullname}
                      </TableCell>
                    </Tooltip>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Collapse>
      </Badge>
    );
  };

export default CustomTable;