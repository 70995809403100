import React, { memo, useMemo } from "react";

interface LinkPreviewProps extends React.PropsWithChildren {
  href?: string;
}
const VideoComponent = memo(({ videoId }: { videoId: string }) => (
  <iframe
    width={"100%"}
    height={"100%"}
    style={{ borderRadius: 10 }}
    src={`https://www.youtube.com/embed/${videoId}`}
    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title="YouTube video player"
  />
));
const LinkPreview: React.FC<LinkPreviewProps> = memo(
  ({ children, href }) => {
    const params = useMemo(
      () => new URLSearchParams(href?.split("?")[1]),
      [href]
    );
    const videoId = useMemo(() => params.get("v"), [params]);

    if (videoId) {
      // const thumbnail = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
      return <VideoComponent key={`${videoId}`} videoId={videoId} />;
    }

    return (
      <a href={href} rel="noreferrer" target="_blank">
        {children}
      </a>
    );
  },
  (prev, next) => prev.href === next.href
);

export default LinkPreview;
