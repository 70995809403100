import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import DialogHeader from "./DialogHeader";
import { useDragItem, useTranslations } from "hooks";
import { getTranslation } from "common";
import {
  AssociationActivity,
  AssociationResponse,
  SelectedProcessModelType,
} from "model/processModel";
import { Cancel, Save } from "@mui/icons-material";
import { Translations } from "model/messageModel";
import UserAvatar from "components/UserAvatar";
import { TASK_STATE_TO_STR } from "common/utilities";
import { processmodelsApi } from "api";

interface ReassociateResponse {
  success: boolean;
  responsemessage: string;
  model: string;
}
interface AssociationDialogProps {
  open: AssociationResponse | null;
  setOpen: React.Dispatch<React.SetStateAction<AssociationResponse | null>>;
  getProcessModel: () => Promise<SelectedProcessModelType>;
  onReassociate: (reassociateResponse: ReassociateResponse) => void;
}
interface ActivityItemProps {
  activity: AssociationActivity;
  translations: Translations;
  disabled: boolean;
  onRemoveAssociation: () => void;
}
interface Ref {
  dragging: boolean;
}

class Design {
  constructor(
    public designerid: string,
    public idtype: number,
    public name: string
  ) {}
}
interface Association {
  oldDesign: Design;
  newDesign: Design;
}

const ActivityItem = forwardRef<Ref, ActivityItemProps>(
  ({ activity, translations, disabled, onRemoveAssociation }, activityRef) => {
    const { dragging, onDrag, onDragEnd, onDragStart, ref } = useDragItem();
    const [openProcesses, setOpenProcesses] = useState<boolean>(false);
    const handleDragStart = (e: React.DragEvent<HTMLElement>) => {
      e.dataTransfer.setData("text/plain", JSON.stringify(activity));
    };

    useImperativeHandle(
      activityRef,
      () => ({
        dragging,
      }),
      [dragging]
    );

    return (
      <React.Fragment>
        <ListItemButton
          ref={ref as any}
          onClick={() => setOpenProcesses((_) => !_)}
          divider
          // disabled={disabled}
          draggable={!disabled}
          onDrag={onDrag}
          onDragEnd={onDragEnd}
          onDragStart={(e) => onDragStart(e, handleDragStart)}
        >
          <ListItemText
            primary={activity.name}
            secondary={getTranslation(
              translations,
              `activity.type.${activity.type}`
            )}
          />
          {disabled && (
            <ListItemSecondaryAction
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Button
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onRemoveAssociation();
                }}
                startIcon={<Cancel />}
              >
                {getTranslation(translations, "generic.reset.label")}
              </Button>
            </ListItemSecondaryAction>
          )}
        </ListItemButton>
        <Collapse inlist={"true"} in={openProcesses}>
          <List sx={{ pl: 4 }}>
            <ListSubheader>
              {getTranslation(
                translations,
                "pm.associations.assigned_activity"
              )}
            </ListSubheader>
            {activity.processes.map(
              ({ idprocess, description, user, state }) => (
                <ListItem
                  divider
                  key={`process-in-activity-associations-${idprocess}`}
                >
                  <ListItemText
                    primary={description}
                    secondary={getTranslation(
                      translations,
                      `pm.label.process_status.${
                        TASK_STATE_TO_STR[Number(state) as any as 0 | 1 | 2]
                      }`
                    )}
                  />
                  <ListItemSecondaryAction>
                    <UserAvatar user={user} showUsername />
                  </ListItemSecondaryAction>
                </ListItem>
              )
            )}
          </List>
        </Collapse>
      </React.Fragment>
    );
  }
);
const AssociationDialog: React.FC<AssociationDialogProps> = ({
  open,
  setOpen,
  getProcessModel,
  onReassociate,
}) => {
  const translations = useTranslations();
  const [dragging, setDragging] = useState(false);
  const [associations, setAssociations] = useState<Association[]>([]);

  useEffect(() => {
    if (!Boolean(open)) {
      setAssociations([]);
    }
  }, [open]);
  const canSave = useMemo(() => {
    // here you can save the reassociations only if you have reassociated all activities
    if (open !== null && Boolean(open)) {
      return (
        open.activities.filter((activity) => {
          return (
            associations.findIndex(
              (association) =>
                association.oldDesign.designerid === activity.designerid
            ) < 0
          );
        }).length <= 0
      );
    }
    return false;
  }, [associations, open]);

  const removeAssociation = (designerid: string) => {
    setAssociations((_associations) => {
      return _associations.filter(
        (_association) => _association.oldDesign.designerid !== designerid
      );
    });
  };
  const onSave = async () => {
    try {
      const processModel = await getProcessModel();
      const { data } = await processmodelsApi.put(
        `${processModel.id}/tokens/reassociate`,
        {
          processmodel: processModel,
          associations: associations.map((association: Association) => ({
            olddesign: {
              designerid: association.oldDesign.designerid,
              idtype: association.oldDesign.idtype,
            },
            newdesign: {
              designerid: association.newDesign.designerid,
              idtype: association.newDesign.idtype,
            },
          })),
        }
      );
      onReassociate(data);
    } catch (e) {
    } finally {
    }
  };
  return (
    <Dialog
      id="association-dialog"
      maxWidth="lg"
      fullWidth
      open={Boolean(open)}
      onClose={() => setOpen(null)}
    >
      <DialogHeader onClose={() => setOpen(null)}>
        {getTranslation(
          translations,
          "generic.associate_old_pending_task_to_new_task.caption"
        )}
      </DialogHeader>
      <DialogTitle sx={{ pl: 2, py: 0 }} variant="subtitle2">
        {getTranslation(translations, "pm.associations.tip")}
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={2} alignItems="flex-start">
          <List sx={{ flex: 1 }}>
            {open &&
              open.activities.map((activity) => (
                <React.Fragment key={activity.designerid}>
                  <ActivityItem
                    onRemoveAssociation={() =>
                      removeAssociation(activity.designerid)
                    }
                    disabled={
                      associations.findIndex(
                        (_) => _.oldDesign.designerid === activity.designerid
                      ) >= 0
                    }
                    ref={(ref) => setDragging(ref?.dragging || false)}
                    activity={activity}
                    translations={translations}
                  />
                </React.Fragment>
              ))}
          </List>
          <Divider orientation="vertical" flexItem />
          <List sx={{ flex: 1 }}>
            {open &&
              open.entities.map((entity) => (
                <React.Fragment key={entity.designerid}>
                  <ListItem
                    divider
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => {
                      e.preventDefault();
                      const source = JSON.parse(
                        e.dataTransfer.getData("text/plain")
                      );
                      const oldDesign = new Design(
                        source.designerid,
                        source.type,
                        source.name
                      );
                      const newDesign = new Design(
                        entity.designerid,
                        entity.type,
                        entity.name
                      );
                      setAssociations((_) => [..._, { oldDesign, newDesign }]);
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: dragging
                          ? "rgba(0,0,0,.2)"
                          : "inherit",
                      },
                    }}
                  >
                    <ListItemText
                      primary={
                        entity.name ||
                        getTranslation(translations, "generic.no_name.caption")
                      }
                      secondary={getTranslation(
                        translations,
                        `activity.type.${entity.type}`
                      )}
                    />
                    <ListItemSecondaryAction>
                      {associations.filter(
                        (_) => _.newDesign.designerid === entity.designerid
                      ).length > 0 && (
                        <Typography
                          variant="subtitle2"
                          sx={{
                            float: "right",
                            textAlign: "right",
                            pl: 2,
                            maxWidth: "75%",
                          }}
                        >
                          {getTranslation(
                            translations,
                            "pm.associations.associated_with"
                          )}
                          :{" "}
                          {associations
                            .filter(
                              (_) =>
                                _.newDesign.designerid === entity.designerid
                            )
                            .map((association) => association.oldDesign.name)
                            .join(", ")}
                        </Typography>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                </React.Fragment>
              ))}
          </List>
          {/* <Box id="bpmnjs_container" /> */}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Button
            variant="contained"
            // color="warning"
            startIcon={<Cancel />}
            size="small"
            onClick={() => setOpen(null)}
          >
            {getTranslation(translations, "user.buttoncancel.label")}
          </Button>
          <Tooltip
            title={
              !canSave &&
              getTranslation(
                translations,
                "generic.reassociation.warning.label"
              )
            }
          >
            <span>
              <Button
                variant="contained"
                // color="success"
                startIcon={<Save />}
                disabled={!canSave}
                type="submit"
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  onSave();
                }}
              >
                {getTranslation(translations, "pm.button.save")}
              </Button>
            </span>
          </Tooltip>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default AssociationDialog;
