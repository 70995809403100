import React, { useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslations, useForm } from "hooks";
import { getTranslation } from "common";
import {
  Grid,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { Validation, isEmail, isRequired } from "hooks/useForm";
import { usersApi } from "api";
import { AuthContext } from "contexts";
import RegisterImage from "image/register.png"
import FlowentiLogo from "image/Logo.svg"
import AuthFlowLayout from "components/layouts/AuthFlowLayout";

interface RegistrationForm {
  email: String;
  lastname: String;
  name: String;
}

const Registration = () => {
  const translations = useTranslations();
  // const dispatch = useDispatch();
  const {
    actions: { setRegisterUser },
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const jwt = searchParams.get("jwt");
  const emailParam = searchParams.get("email");

  const validations: Validation[] = [
    ({ email }) =>
      isRequired(email) || {
        email: getTranslation(translations, "register.fieldrequired"),
      },
    ({ email }) =>
      isEmail(email) || {
        email: getTranslation(translations, "register.valid.Error_after_at"),
      },
    ({ firstname }) =>
      isRequired(firstname) || {
        firstname: getTranslation(translations, "register.fieldrequired"),
      },
    ({ lastname }) =>
      isRequired(lastname) || {
        lastname: getTranslation(translations, "register.fieldrequired"),
      },
  ];
  const { values, changeHandler, errors, touched, isValid } = useForm(
    {
      email: emailParam ?? "",
      firstname: "",
      lastname: "",
    },
    validations
  );
  const saveData = async (user: RegistrationForm) => {
    if (emailParam && jwt) {
      await usersApi.put(jwt, {
        user,
      });
      navigate("/login");
    } else {
      try {
        const result = await usersApi.post("", {
          user: {
            ...user,
            subscriptiontype: 0,
          },
        });
        setRegisterUser({
          userId: result.data.id,
          jwt: result.headers["token"],
          ...result.data,
        });
        navigate("/organization");
      } catch {
      } finally {
      }
    }
  };

  return (
    <AuthFlowLayout
      rightAltImage="right image alt"
      rightImage={RegisterImage}
      title="Flowenti"
      image={FlowentiLogo}
      alt="image logo"
      cardTitle={getTranslation(
        translations,
        "register.label.register_an_account"
      )}>
      <Grid container component="form" spacing={2}>
        <Grid item xs={12}>
          <TextField
            disabled
            id="registration-email"
            size="small"
            label={getTranslation(
              translations,
              "register.registration.email.label"
            )}
            fullWidth
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            value={values.email}
            onChange={({ target }) =>
              changeHandler("email", target.value)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="registration-name"
            size="small"
            label={getTranslation(
              translations,
              "register.registration.name.label"
            )}
            fullWidth
            error={Boolean(touched.firstname && errors.firstname)}
            helperText={touched.firstname && errors.firstname}
            value={values.firstname}
            onChange={({ target }) =>
              changeHandler("firstname", target.value)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="registration-lastname"
            size="small"
            label={getTranslation(
              translations,
              "register.registration.lastname.label"
            )}
            fullWidth
            error={Boolean(touched.lastname && errors.lastname)}
            helperText={touched.lastname && errors.lastname}
            value={values.lastname}
            onChange={({ target }) =>
              changeHandler("lastname", target.value)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: getTranslation(
                translations,
                "register.label.present_cond_privacy"
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            id="registration-button"
            variant="contained"
            fullWidth
            type="submit"
            disabled={!isValid}
            onClick={(e) => {
              e.preventDefault();
              saveData(values);
            }}
          >
            {getTranslation(
              translations,
              "register.label.button_register_confirm"
            )}
          </Button>
        </Grid>
      </Grid>
    </AuthFlowLayout>
  );
};

export default Registration;
