import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import BpmnViewer from "bpmn-js/lib/NavigatedViewer";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Portal,
} from "@mui/material";
import { getTranslation } from "common";
import { useTranslations } from "hooks";

interface PreviewAiModelProps {
  uuid: string;
  model: string;
  onApply?: (model: string) => Promise<void>;
}

const setModel = (id: string, idParent: string, model: string) => {
  const canvas = document.getElementById(id) as HTMLCanvasElement;
  const parent = document.getElementById(idParent) as HTMLElement;
  canvas && (canvas.innerHTML = "");

  const bpmnViewer = new BpmnViewer({
    container: canvas,
    readOnly: true,
    selectable: false,
    additionalModules: [],
  });
  (async () => {
    await bpmnViewer.importXML(model);
    const _canvas = bpmnViewer.get("canvas") as any;
    const viewbox = _canvas.viewbox();

    parent.style.height = viewbox.height;
    canvas.style.height = viewbox.height;
    _canvas.zoom("fit-viewport");
  })();
};

const PreviewAiModel: React.FC<PreviewAiModelProps> = ({
  model,
  onApply,
  uuid,
}) => {
  const parentId = useMemo(() => `container-ai-model-${uuid}`, [uuid]);
  const idElement = useMemo(() => `ai-${uuid}`, [uuid]);
  const translations = useTranslations();
  const parentIdElementPreview = useMemo(
    () => `preview-container-ai-model-${uuid}`,
    [uuid]
  );
  const idElementPreview = useMemo(() => `preview-ai-${uuid}`, [uuid]);

  const [openPreview, setOpenPreview] = useState<boolean>(false);

  useEffect(() => {
    setModel(idElement, parentId, model);
  }, [idElement, model, parentId]);

  const handlePreview = () => {
    setOpenPreview(true);
  };

  useLayoutEffect(() => {
    if (openPreview) {
      setTimeout(() => {
        setModel(idElementPreview, parentIdElementPreview, model);
      });
    }
  }, [idElementPreview, model, openPreview, parentIdElementPreview]);

  return (
    <React.Fragment>
      <Box sx={{ position: "relative" }} id={parentId}>
        <Box sx={{ position: "absolute", inset: 0, zIndex: 10 }}>
          <Box sx={{ position: "relative", height: "100%", width: "100%" }}>
            <Button
              onClick={handlePreview}
              color="secondary"
              variant="contained"
              size="small"
              sx={{ position: "absolute", bottom: 2, right: 2 }}
            >
              {getTranslation(translations, "ai_assistant.action")}
            </Button>
          </Box>
        </Box>
        <Box
          id={idElement}
          sx={{
            "& .bjs-drilldown": { display: "none" },
            "& .djs-minimap": { display: "none" },
            "& .djs-palette": { display: "none" },
            width: 250,
            // height: 200,
            pointerEvents: "none",
          }}
          className="canvas"
        />
      </Box>
      <Portal container={document.body}>
        <Dialog
          sx={{ zIndex: 99999999 }}
          onClose={() => setOpenPreview(false)}
          open={openPreview}
          maxWidth="md"
          fullWidth
        >
          <DialogContent id={parentIdElementPreview} sx={{ height: "75vh" }}>
            <Box
              id={idElementPreview}
              sx={{
                "& .bjs-drilldown": { display: "none" },
                "& .djs-minimap": { display: "none" },
                "& .djs-palette": { display: "none" },
                width: "100%",
                height: "100%",
                pointerEvents: "none",
              }}
              className="canvas"
            />
          </DialogContent>
          {onApply && (
            <DialogActions>
              <Button variant="contained" onClick={() => onApply(model)}>
                {getTranslation(translations, "apply_ai_edit.to.model")}
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </Portal>
    </React.Fragment>
  );
};

export default PreviewAiModel;
