import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslations } from "hooks";
import { getTranslation } from "common";
import DraggableTaskCard from "./DraggableTaskCard";
import {
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  Droppable,
} from "react-beautiful-dnd";
import { SequenceFlow, Task } from "model/activitiesModel";
import React, { useEffect, useRef } from "react";
import noTaskDoing from "../image/img_doing.svg";
import TaskCard from "./TaskCard";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  getOffsetHeightForTask,
  LABEL_OFFSET,
  TASK_CARD_HEIGHT,
} from "common/utilities";
interface DoingPanelProps {
  userId: number;
  all: boolean;
  loading: boolean;
  labelCompleteMode: boolean;
  switchChecked: boolean;
  administrator?: boolean;
  dragItem: Task | null;
  processedTasks: Task[];
  handleSequenceFlowChoice: (task: Task, sequenceFlow: SequenceFlow) => void;
  goTodo: (task: Task) => Promise<void>;
  goToDone: (task: Task) => Promise<void>;
  setDetail: (idprocesstoken: number) => void;
}
type RowProps = {
  index: number;
  style: Object;
};
const HEADER_HEIGHT = 65;
const DoingPanel: React.FC<DoingPanelProps> = ({
  handleSequenceFlowChoice,
  goToDone,
  goTodo,
  all,
  switchChecked,
  dragItem,
  labelCompleteMode,
  administrator,
  loading,
  setDetail,
  processedTasks,
}) => {
  const translations = useTranslations();
  const listRef = useRef<List<Task>>(null);

  if (window.Cypress) {
    window.Cypress.on("scroll-doing", (idprocesstoken: number) =>
      listRef.current?.scrollToItem(idprocesstoken)
    );
  }
  useEffect(() => {
    listRef.current?.resetAfterIndex(0);
  }, [processedTasks])
  
  const Row: React.FC<RowProps> = React.useMemo(
    () =>
      ({ index, style }) => {
        const task = processedTasks[index];
        if (!task) {
          return null;
        }
        return (
          <span style={style}>
            <DraggableTaskCard
              index={index}
              labelCompleteMode={labelCompleteMode}
              key={task.idprocesstoken}
              handleSequenceFlowChoice={handleSequenceFlowChoice}
              all={all}
              task={task}
              switchChecked={switchChecked}
              nextButtonLabel={getTranslation(
                translations,
                "pm.label.process_status.done"
              )}
              handleNextStep={goToDone}
              handleBackStep={goTodo}
              administrator={administrator}
              setDetail={(idprocesstoken) => setDetail(idprocesstoken)}
            />
          </span>
        );
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [processedTasks]
  );

  return (
    <Stack
      sx={{
        flex: 1,
        maxHeight: `100%`,
        backgroundColor: (_) => _.palette.grey[200],
        borderRadius: "15px",
      }}
    >
      <Box sx={{ height: HEADER_HEIGHT }}>
        <Typography sx={{ flex: 1, textAlign: "start" }} variant="h6">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ml: "10px",
              mt: "6px",
            }}
          >
            <div
              style={{
                height: "10px",
                width: "10px",
                borderRadius: "50%",
                backgroundColor: "#FFA500",
                marginRight: "8px",
              }}
            />
            {`${getTranslation(
              translations,
              "pm.label.process_status.doing"
            )} (${processedTasks.length})`}
          </Box>
        </Typography>
        <Divider
          sx={{
            height: "3px",
            borderRadius: "10px",
            backgroundColor: "#FFA500",
            mt: 4,
            mb: 3,
            width: "85%",
            mx: "auto",
          }}
        />
      </Box>
      <Droppable
        isDropDisabled={dragItem?.state === 1}
        droppableId="doing"
        mode="virtual"
        renderClone={(
          provided: DraggableProvided,
          snapshot: DraggableStateSnapshot,
          rubric: DraggableRubric
        ) => (
          <span {...provided.draggableProps} {...provided.dragHandleProps}>
            <TaskCard
              labelCompleteMode={labelCompleteMode}
              switchChecked={false}
              setDetail={(idprocesstoken) => setDetail(idprocesstoken)}
              task={dragItem || processedTasks[rubric.source.index]}
            />
          </span>
        )}
      >
        {(provided, snapshot) => (
          <Box
            sx={{
              maxHeight: `calc(100% - ${HEADER_HEIGHT}px})`,
              height: `calc(100% - ${HEADER_HEIGHT}px)`,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              pb: 2,
            }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {processedTasks.length <= 0 && !loading && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Box
                  component="img"
                  alt="..."
                  sx={{
                    width: "208px",
                    height: "208px",
                  }}
                  src={noTaskDoing}
                />
                <Typography color="textSecondary" component="p" sx={{ mt: 1 }}>
                  {getTranslation(
                    translations,
                    "generic.no_ongoing_activity.caption"
                  )}
                </Typography>
              </Box>
            )}
            {loading && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <CircularProgress />
              </Box>
            )}
            <AutoSizer style={{ height: `calc(100% - ${HEADER_HEIGHT}px)` }}>
              {({
                height,
                width,
              }: {
                height: number;
                width: number;
                scaledWidth: number;
                scaledHeight: number;
              }) => (
                <List
                  ref={listRef}
                  height={height}
                  itemCount={loading ? 0 : processedTasks.length}
                  itemSize={(index) =>
                    TASK_CARD_HEIGHT +
                    10 +
                    (labelCompleteMode ? LABEL_OFFSET : 0) +
                    getOffsetHeightForTask(processedTasks[index].userdataLength)
                  }
                  width={width}
                  overscanCount={5}
                >
                  {Row}
                </List>
              )}
            </AutoSizer>
          </Box>
        )}
      </Droppable>
    </Stack>
  );
};

export default DoingPanel;
