import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
  Typography,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { getTranslation, toDataUrl } from "common";
import ImagePicker from "components/ImagePicker";
import {
  useForm,
  useSelectedOrganization,
  useSnackbar,
  useTranslations,
} from "hooks";
import React, { useEffect, useState } from "react";
import DialogHeader from "./DialogHeader";
import { Validation, isRequired } from "hooks/useForm";
import { robotsApi } from "api";
import { Robot } from "hooks/useRobots";
import { AutoAwesome, ContentCopy } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import dayjs from "dayjs";

interface RobotDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  onOpenAi?: () => void;
  defaultRobot?: Robot;
  userId?: string;
  updateVersion: (id: number | undefined | null) => void;
}

interface Category {
  id: number;
  description: string;
}

const RobotDialog: React.FC<RobotDialogProps> = ({
  open,
  onClose,
  onOpenAi,
  defaultRobot = undefined,
  onSave,
  userId,
  updateVersion,
}) => {
  const [created, setCreated] = useState(false);
  const organization = useSelectedOrganization();
  const translations = useTranslations();
  const { sendSnack } = useSnackbar();
  const [categories, setCategories] = useState<Category[]>([]);
  const validations: Validation[] = [
    ({ name }) =>
      isRequired(name) || {
        name: getTranslation(translations, "pm.newfolder.required.name"),
      },

    //per rendere la categoria obbligatoria
    // ({ category }) =>
    //   isRequired(category) || {
    //     category: getTranslation(translations, "pm.newfolder.required.category"),
    //   },
  ];
  const { values, changeHandler, errors, isValid, touched, reset } = useForm(
    { ...defaultRobot, _picture: null },
    validations
  );

  useEffect(() => {
    reset(defaultRobot);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultRobot]);

  useEffect(() => {
    if (!open) {
      reset();
      setCreated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleRefreshRobotApiKey = async () => {
    try {
      const robotId = created ? values.id : defaultRobot?.id;
      const { data } = await robotsApi.put(`${robotId}/refreshtoken`, {});
      reset({
        ...defaultRobot,
        ...values,
        apikey: data.token,
        expiration_date: data.expiration_date,
      });
      sendSnack({
        message: data.message,
        type: "success",
      });
    } catch (error) {
    } finally {
      onSave();
    }
  };

  const handleSaveRobot = async () => {
    if (defaultRobot || created) {
      try {
        let picture = null;
        if (values._picture) {
          picture = await toDataUrl(values._picture);
        }
        const robotId = created ? values.id : defaultRobot?.id;
        const { data } = await robotsApi.put(`${robotId}`, {
          ...values,
          picture,
          category: values.idcategory,
          idcategory: values.idcategory,
        });
        sendSnack({
          message: data.responsemessage,
          type: "success",
        });
        onSave();
        updateVersion(robotId);
        onClose();
      } catch {
      } finally {
      }
    } else {
      try {
        let picture = null;
        if (values._picture) {
          picture = await toDataUrl(values._picture);
        }
        const { data } = await robotsApi.post("", {
          ...values,
          picture,
          category: values.idcategory,
          idcategory: values.idcategory,
        });
        //  organizationsApi.put(`${organization?.id}/robots/${data.id}`);
        const { data: robot } = await robotsApi.get<Robot>(`${data.id}`);
        reset({
          ...robot,
          expiration_date: dayjs(robot.expiration_date).format(
            "DD-MM-YYYY HH:mm"
          ),
        });
        sendSnack({
          message: data.responsemessage,
          type: "success",
        });
        setCreated(true);
        onSave();
      } catch {
      } finally {
      }
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await robotsApi.get("/categories");
        if (data) {
          setCategories(data.categories);
        } else {
          setCategories([]);
        }
      } catch {}
    };
    fetchCategories();
  }, [open]);

  const handleCategoryChange = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const value = event.target.value;
    changeHandler("idcategory", value);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"}>
      <DialogHeader onClose={onClose}>
        <Typography variant="h5">
          {getTranslation(translations, "configure.robot.dialog.title")}
        </Typography>
      </DialogHeader>
      <DialogContent>
        <Stack direction="row" sx={{ pt: (_) => _.spacing(0.7) }} spacing={2}>
          <Box flex={1} display="flex" flexDirection="column" gap={2}>
            <ImagePicker
              photoUrl={values._picture}
              relativePath={values.picture}
              setPhotoUrl={(value) => changeHandler("_picture", value)}
            />
            <FormControl fullWidth size="small">
              <InputLabel id="category-select-label">
                {getTranslation(translations, "select_category.label")}
              </InputLabel>
              <Select
                id="category-select"
                value={values.idcategory || defaultRobot?.idcategory || ""}
                onChange={handleCategoryChange}
                label={getTranslation(translations, "select_category.label")}
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Grid component="form" flex={2} container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="robot-name-field"
                variant="outlined"
                fullWidth
                size="small"
                label={getTranslation(
                  translations,
                  "register.registration.name.label"
                )}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                value={values?.name || ""}
                onChange={({ target }) => changeHandler("name", target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="robot-description-field"
                variant="outlined"
                fullWidth
                size="small"
                label={getTranslation(
                  translations,
                  "pm.errors.label.description"
                )}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                value={values?.description || ""}
                onChange={({ target }) =>
                  changeHandler("description", target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={getTranslation(
                          translations,
                          "generic.copy_to_clipboard"
                        )}
                      >
                        <span>
                          <IconButton
                            id="copy-robot-apikey"
                            disabled={!values.apikey}
                            onClick={() =>
                              navigator.clipboard.writeText(values.apikey)
                            }
                            size="small"
                            type="button"
                            aria-label="copy-apikey"
                          >
                            <ContentCopy fontSize="small" />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <IconButton
                        id="refresh-robot-apikey"
                        onClick={handleRefreshRobotApiKey}
                        //   variant="outlined"
                        size="small"
                        disabled={!created && !defaultRobot}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                fullWidth
                disabled={!values.apikey}
                size="small"
                label={
                  !values.apikey
                    ? getTranslation(
                        translations,
                        "robots.label.save_to_obtain_api_key"
                      )
                    : getTranslation(translations, "robots.label.api_key")
                }
                helperText={
                  // getTranslation(translations, "robots.key.expiration.date") +
                  // ": " +
                  values.expiration_date
                    ? `${getTranslation(
                        translations,
                        "robots.key.expiration.date"
                      )}: ${values.expiration_date}`
                    : undefined
                }
                value={values?.apikey || ""}
              />
            </Grid>
            {onOpenAi && (
              <Grid
                item
                xs={12}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"end"}
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={onOpenAi}
                  endIcon={<AutoAwesome />}
                >
                  Boilerplate AI
                </Button>
              </Grid>
            )}


            {/* <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                label={getTranslation(translations, "robot.owner.label")}
                value={defaultRobot ? defaultRobot.iduserowner : userId}
                disabled
              />
            </Grid> */}
            <Grid
              xs={12}
              item
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button onClick={onClose} variant="outlined" size="small">
                  {getTranslation(translations, "generic.button.cancel")}
                </Button>
                <Button
                  id="save-robot-btn"
                  onClick={handleSaveRobot}
                  variant="outlined"
                  size="small"
                  disabled={!isValid}
                >
                  {getTranslation(translations, "generic.button.savechanges")}
                </Button>
                {/* <Button
                  onClick={handleRefreshRobotApiKey}
                  variant="outlined"
                  size="small"
                // disabled={!isValid}
                >
                  <RefreshIcon />
                </Button> */}
                {/* <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  label="Organizzazione"
                  value={organization?.name || ""}
                  disabled
                /> */}
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default RobotDialog;
