import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DialogHeader from "./DialogHeader";
import { create_UUID, getTranslation } from "common";
import { useTranslations } from "hooks";
import { SupportedUserDataTypes } from "hooks/useUserDataKeys";
import { Add, CheckBox, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

export interface Data {
  type: SupportedUserDataTypes;
  name: string;
  id: string;
  inCard: boolean;
}

interface ProcessModelSchemaProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: (rows: Data[]) => Promise<void>;
  schema: Data[];
}

const DEFAULT_ROW = () => ({
  type: SupportedUserDataTypes.string,
  name: "",
  id: create_UUID(),
  inCard: false
});

const ProcessModelSchema: React.FC<ProcessModelSchemaProps> = ({
  open,
  setOpen,
  onSave,
  schema,
}) => {
  const translations = useTranslations();
  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<Data[]>([...schema, { ...DEFAULT_ROW() }]);

  const addRow = () => {
    setRows((prev) => {
      const cp = [...prev, { ...DEFAULT_ROW() }];
      return cp;
    });
  };
  const onDelete = (idx: number) => {
    setRows((prev) => {
      const cp = [...prev];
      cp.splice(idx, 1);
      return cp;
    });
  };

  useEffect(() => {
    if (!open) {
      setRows([...schema, { ...DEFAULT_ROW() }]);
    }
  }, [open, schema]);

  const handleSave = async () => {
    try {
      setLoading(true);
      await onSave(rows.filter((a) => a.name));
      setOpen(false);
    } catch (e) {
      console.error(String(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      id="process-model-schema-dialog"
      maxWidth="md"
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogHeader sx={{ px: 6 }} onClose={() => setOpen(false)}>
        {getTranslation(translations, "process_model.schema.title")}
      </DialogHeader>
      <DialogTitle sx={{ pl: 6, py: 0 }} variant="subtitle2">
        {getTranslation(translations, "process_model.schema.description")}
      </DialogTitle>
      <DialogContent>
        <Stack gap={3} pt={2} flexDirection={"column"}>
          {rows.map((row, idx) => (
            <Stack
              component="form"
              direction="row"
              key={`schema-field-${row.id}`}
              spacing={1}
            >
              <TextField
                size="small"
                sx={{ flex: 1 }}
                label={getTranslation(
                  translations,
                  "process_model.schema.field.name"
                )}
                value={row.name}
                onKeyUp={(e) => e.stopPropagation()}
                onChange={(e) => {
                  setRows((prev) => {
                    const cp = [...prev];
                    cp[idx].name = e.target.value;
                    return cp;
                  });
                }}
                id={`schema-field-name-${idx}`}
              />
              <TextField
                size="small"
                sx={{ flex: 1 }}
                label={getTranslation(
                  translations,
                  "process_model.schema.field.type"
                )}
                select
                value={row.type}
                onChange={({ target }) =>
                  setRows((prev) => {
                    const cp = [...prev];
                    cp[idx].type =
                      target.value as unknown as SupportedUserDataTypes;
                    return cp;
                  })
                }
                id={`schema-field-type-${idx}`}
              >
                {Object.entries(SupportedUserDataTypes)
                  .filter(([v]) => isNaN(Number(v)))
                  .map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {getTranslation(
                        translations,
                        `supported.userdata.type.${key}`
                      )}
                    </MenuItem>
                  ))}
              </TextField>
              <Stack direction="row">
                <Tooltip
                  title={getTranslation(translations, "generic.showingincard.caption")}
                >
                  <Checkbox
                  id={`check-${idx}`}
                    size="small"
                    checked={Boolean(row.inCard)}
                    onChange={(e, checked) => {
                      setRows((prev) => {
                        const cp = [...prev];
                        cp[idx].inCard = checked;
                        return cp;
                      });
                    }}
                  />
                </Tooltip>
                <IconButton
                  id={`del-${row.name}`}
                  disabled={!row.name}
                  sx={{ opacity: row.name ? 1 : 0 }}
                  onClick={() => onDelete(idx)}
                >
                  <Delete />
                </IconButton>
              </Stack>
            </Stack>
          ))}
          <Button
            disabled={rows.some((a) => !a.name)}
            onClick={addRow}
            startIcon={<Add />}
            id="add-userSchema"
          >
            {getTranslation(translations, "process_model.schema.add_field")}
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          disabled={loading}
          onClick={handleSave}
          id="save-btn"
        >
          {getTranslation(translations, "pm.button.save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ProcessModelSchema;
