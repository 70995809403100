import { useEffect, useState, useRef, useCallback } from "react";
import ZoomScroll from "diagram-js/lib/navigation/zoomscroll/ZoomScroll";
// @ts-ignore
import BpmnColorPickerModule from "bpmn-js-color-picker";
// @ts-ignore
import minimapModule from "diagram-js-minimap";
import ContextPadProvider from "bpmn-js/lib/features/context-pad/ContextPadProvider";
import customModule from "../../Component/Pages/Dashboard/Process/NewProcessModule/CustomPaletteProvider";
import {
  BpmnPropertiesPanelModule,
  BpmnPropertiesProviderModule,
  // @ts-ignore
} from "bpmn-js-properties-panel";
import BpmnModeler from "bpmn-js/lib/Modeler";
import { Root, Shape } from "bpmn-js/lib/model/Types";
import LabelEditingModule from "bpmn-js/lib/features/label-editing";
import LabelEditingProvider from "bpmn-js/lib/features/label-editing/LabelEditingProvider";
import CamundaExtensionModule from "camunda-bpmn-moddle/resources/camunda.json";

// import _ from "lodash";
import customModdleExtension from "../../Component/Pages/Dashboard/Process/NewProcessModule/custom.json";
import { CustomReplaceMenuProvider } from "../../Component/Pages/Dashboard/Process/NewProcessModule/CustomReplaceMenuProvider";
import {
  CreateAppendAnythingModule,
  // @ts-ignore
} from "bpmn-js-create-append-anything";
import "../../Component/Pages/Dashboard/Process/NewProcessModule/CustomReplaceMenuProvider/AppendMenuReplace";
import "../../Component/Pages/Dashboard/Process/NewProcessModule/CustomReplaceMenuProvider/CustomReplaceMenuProvider";
// import TooltipInfoServiceModule from "../../common/ToolTipInfoServces";
import { diagramXML } from "../../Component/Pages/Dashboard/Process/NewProcessModule/newDiagram";
import { processmodelsApi } from "api";
import { CONSTANTS } from "common";
import {
  ExtensionElementType,
  ExtensionElements,
  ExtensionSubprocesses,
} from "model/processModel";
import { Data } from "components/dialogs/ProcessModelSchema";
import { getUserSchema } from "common/utilities";

if (!minimapModule.minimap[1].prototype.__toggle) {
  minimapModule.minimap[1].prototype.__toggle =
    minimapModule.minimap[1].prototype.toggle;
  minimapModule.minimap[1].prototype.toggle = function (open: boolean) {
    if (this.__toggle) {
      this.__toggle(open);
    }
    localStorage.setItem(
      CONSTANTS.STORE_MINIMAP_OPEN,
      JSON.stringify(this.isOpen())
    );
  };
}

interface EntityTypes {
  idsourceref: string;
  idtargetref: string;
  name: string;
  designerid: string;
  documentation: string;
  idprocessmodel: number;
  id: number;
}

export interface ExtensionFlownodesType {
  source: string;
  linkedto: string;
}

export interface ExtensionSwimLanesType {
  source: string;
  assignmentmode: number;
}

export interface ProcessModelDataTypes {
  entities: Array<EntityTypes>;
  model: string;
  extensionelements: Array<ExtensionElementType>;
  extensionflownodes: Array<ExtensionFlownodesType>;
  extensionswimlanes: Array<ExtensionSwimLanesType>;
  extensionsubprocesses: Array<ExtensionSubprocesses>;
  idorganization: number;
  hasprocess: boolean;
  name: string;
  version: number;
  modeltype: number;
  creation: string | null;
  id: number;
}

interface UseBpmnJsModelerTypes {
  selectedDesignerId?: string;
  getUsername: (user: {
    username?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
  }) => string;
  pathname: string;
  editId: string;
  setUserschema: (data: Data[]) => void;
  setProcessModelName: (procesmodelname: string) => void;
  setModelType: (value: number) => void;
}

const useBpmnModeler = (props: UseBpmnJsModelerTypes) => {
  const {
    getUsername,
    pathname,
    editId,
    setProcessModelName,
    setModelType,
    selectedDesignerId,
  } = props;
  const originalXml = useRef<string>("");
  const [extensionElements, setExtensionElements] =
    useState<ExtensionElements>();
  const [bpmnModeler, setBpmnModeler] = useState<any | null>(null);
  // const [bpmnModeler, setBpmnModeler] = useState<BpmnModeler | null>(null);
  const [selectedLane, setSelectedLane] = useState<string>("");
  // const [isViewer, setIsViewer] = useState<boolean>(false);
  const [selectedElements, setSelectedElement] = useState<any>([]);
  const [currentElement, setCurrentElement] = useState<any>(null);
  const [shouldShowDropZoneError, setShouldShowDropZoneError] =
    useState<boolean>(false);

  const [processModelData, setProcessModelData] =
    useState<ProcessModelDataTypes>({
      entities: [],
      model: "",
      extensionelements: [],
      extensionflownodes: [],
      extensionswimlanes: [],
      extensionsubprocesses: [],
      idorganization: 0,
      name: "",
      hasprocess: false,
      version: 0,
      modeltype: 0,
      creation: null,
      id: 0,
    });

  const selectedElementsRef = useRef<any>(null);
  const extensionElementsRef = useRef<any>({});
  const setExtensionElementsWithRef = (extensionElements: ExtensionElements) => {
    setExtensionElements(extensionElements);
    extensionElementsRef.current = extensionElements;
  }
  const extensionSubprocessesRef = useRef<ExtensionSubprocesses[]>([]);
  const currentElementRef = useRef<any>(null);
  const laneElementIdsRef = useRef<Array<string>>([]);
  const primaryPropertiesNameRef = useRef<any>({});
  useEffect(() => {
    let canvas = document.getElementById("js-canvas") as HTMLCanvasElement;
    canvas && (canvas.innerHTML = "");
    let minimapOpen = JSON.parse(
      localStorage.getItem(CONSTANTS.STORE_MINIMAP_OPEN) || "true"
    );

    let bpmnModeler = new BpmnModeler({
      container: canvas,
      keyboard: {
        bindTo: document,
      },

      additionalModules: [
        BpmnPropertiesPanelModule,
        BpmnPropertiesProviderModule,
        CustomReplaceMenuProvider,
        CreateAppendAnythingModule,
        minimapModule,
        BpmnColorPickerModule,
        LabelEditingModule,
        LabelEditingProvider,

        CamundaExtensionModule,
        // TooltipInfoServiceModule,
        ContextPadProvider,
        customModule,
        ZoomScroll,
      ],

      moddleExtensions: {
        custom: {
          custom: customModdleExtension,
          lane: ["associationName"], // Add the "associationName" property to the "lane" element
        },
      },
      minimap: {
        open: minimapOpen,
      },
    });
    setBpmnModeler(bpmnModeler);
  }, []);
  const disableDoubleClickForElements = useCallback(
    (event: any, { element }: any) => {
      if (element.type === "bpmn:Lane" || element.type === "bpmn:SubProcess") {
        event.stopPropagation();
        return;
      }
    },
    []
  );

  useEffect(() => {
    if (bpmnModeler) {
      bpmnModeler.on("element.dblclick", 1500, disableDoubleClickForElements);
      return () => {
        bpmnModeler.off("element.dblclick", disableDoubleClickForElements);
      };
    }
  }, [bpmnModeler, disableDoubleClickForElements]);

  useEffect(() => {
    extensionSubprocessesRef.current = processModelData.extensionsubprocesses;
  }, [processModelData]);

  const openDiagram = useCallback(
    async (
      xml: string,
      shouldShowInTheViewPort: boolean,
      onSuccess?: () => void
    ) => {
      try {
        if (bpmnModeler) {
          originalXml.current = xml;
          await bpmnModeler.importXML(xml);

          if (bpmnModeler) {
            const canvas = bpmnModeler.get("canvas");
            let elementRegistry = canvas._elementRegistry;
            if (shouldShowInTheViewPort) handleCanvasViewPort();
            ///////////////////////property panel ////////////////////////
            bpmnModeler.on("selection.changed", (e: any) => {
              selectedElementsRef.current = e.newSelection;
              currentElementRef.current = e.newSelection[0];
              if (e.newSelection[0] && e.newSelection[0].type === "bpmn:Lane") {
                setSelectedLane(e.newSelection[0].id);
              } else {
                setSelectedLane("");
              }
              // if (e.newSelection && e.newSelection.length) {
              setSelectedElement(e.newSelection);
              setCurrentElement(e.newSelection[0]);
            });

            bpmnModeler.on("element.changed", (e: any) => {
              const { element } = e;
              if (element.type === "bpmn:Task") {
                setProcessModelData((_) => ({
                  ..._,
                  extensionsubprocesses:
                    extensionSubprocessesRef.current.filter(
                      ({ source }) => element.id !== source
                    ),
                }));
              }

              if (!currentElementRef.current) {
                return;
              }

              // update panel, if currently selected element changed
              if (element?.id === currentElementRef.current?.id) {
                const originalElement = bpmnModeler
                  .get("elementRegistry")
                  .get(element?.id);
                setCurrentElement(originalElement);
                currentElementRef.current = element;
              }
            });

            ////////////////////property panel///////////////////

            bpmnModeler.on("commandStack.execute", (event: any) => {
              const { command, context } = event;

              const listOfLaneIds: Array<string> = [
                ...laneElementIdsRef.current,
              ];
              if (command === "elements.delete") {
                context.elements.forEach((element: any) => {
                  if (element.type === "bpmn:Lane") {
                    delete extensionElementsRef.current[element.id];
                  }
                });
              }
              if (command === "lane.updateRefs") {
                context.laneShapes.forEach((eachLane: any) => {
                  // const label = eachLane.name;

                  if (!laneElementIdsRef.current.includes(eachLane.id)) {
                    listOfLaneIds.push(eachLane.id);
                    laneElementIdsRef.current = listOfLaneIds;
                    // addDragAndDropEventListenersToLanes(eachLane);
                  }
                });
              }
              if (command === "lane.split") {
                context.shape.children.forEach((splittedLane: any) => {
                  if (!laneElementIdsRef.current.includes(splittedLane.id)) {
                    listOfLaneIds.push(splittedLane.id);
                    laneElementIdsRef.current = listOfLaneIds;

                    addDragAndDropEventListenersToLanes(splittedLane);
                  }
                });
              }
              if (command === "lane.add") {
                listOfLaneIds.push(event.context.newLane.id);
                laneElementIdsRef.current = listOfLaneIds;

                addDragAndDropEventListenersToLanes(event.context.newLane);
              }
            });

            // // Get a reference to the lane element
            elementRegistry.forEach((element: Shape | Root) => {
              if (selectedDesignerId && element.id === selectedDesignerId) {
                const selection = bpmnModeler.get("selection");
                selection.select(element);
              }

              if (element.type === "bpmn:Lane") {
                // element.
                addDragAndDropEventListenersToLanes(element);
              }
            });

            if (onSuccess) onSuccess();
          }
          // });

          setShouldShowDropZoneError(false);
        }
      } catch (err) {
        setShouldShowDropZoneError(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bpmnModeler]
  );

  const getProcessModelXml = useCallback(async () => {
    try {
      const { data } = await processmodelsApi.get(editId);
      props.setUserschema(getUserSchema(data.userschema));
      const extensionElements = data.extensionelements.reduce(
        (result: any, extElement: ExtensionElementType) => {
          extElement.name = getUsername({
            ...extElement,
            firstname: extElement.name,
          });
          if (result[extElement.linkedto]) {
            result[extElement.linkedto].push(extElement);
          } else {
            result[extElement.linkedto] = [extElement];
          }

          return result;
        },
        {}
      );

      // const extensionSubprocesses = data.extensionsubprocesses.reduce(
      //   (result: any, subProcess: ExtensionSubprocessesType) => {

      //   },
      //   {}
      // );

      extensionElementsRef.current = extensionElements;
      setExtensionElements(extensionElementsRef.current);
      localStorage.setItem(
        CONSTANTS.STORE_EXTENSIONS_ELEMENTS,
        JSON.stringify(extensionElementsRef.current)
      );
      setProcessModelName(data?.name);
      setModelType(data.modeltype);
      setProcessModelData({ ...data });
      openDiagram(data.model, true);
    } catch (e) {
      console.error(e);
    } finally {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, openDiagram]);

  const keyDownEventListener = (event: any) => {
    const propertyPanel = document.querySelector("#js-properties-panel");

    if (propertyPanel && propertyPanel.contains(document.activeElement)) {
      return;
    }
    if (bpmnModeler) {
      const commandStack = bpmnModeler.get("commandStack");
      const selection = bpmnModeler.get("selection");
      if (selection) {
        if (event.code === "Escape") {
          selection.select(null);
        } else if (event.code === "Delete") {
          const selectedElement = selection.get();
          const modeling = bpmnModeler.get("modeling");
          modeling.removeElements(selectedElement);
        } else if (event.ctrlKey && event.code === "KeyZ") {
          commandStack.undo();
        } else if (event.ctrlKey && event.code === "KeyY") {
          commandStack.redo();
        }
      }
    }
    // Note: //////////For Selecting All///////////
    // else if (event.ctrlKey && event.code === "KeyA") {
    //   const elements = bpmnModeler.get("elementRegistry").getAll();
    //   selection.select(elements);
    // }
  };

  useEffect(() => {
    if (!!bpmnModeler) {
      if (pathname.includes("edit")) {
        getProcessModelXml();
      }
      // isViewer is false and never change
      // else if (isViewer) {
      //   openDiagram("", true);
      // }
      else {
        openDiagram(diagramXML, true);
      }

      document.addEventListener("keydown", keyDownEventListener);
    }
    return () => {
      document.removeEventListener("keydown", keyDownEventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bpmnModeler, getProcessModelXml]);

  const handleCanvasViewPort = async () => {
    bpmnModeler && (await bpmnModeler.get("canvas").zoom("fit-viewport"));
  };

  const handleDragOverOfLaneElement = (event: any) => event.preventDefault();

  const addDragAndDropEventListenersToLanes = (lane: any) => {
    try {
      if (bpmnModeler) {
        const laneElementGfx = bpmnModeler
          .get("elementRegistry")
          .getGraphics(lane.id);

        laneElementGfx?.addEventListener("dragover", (event: any) =>
          handleDragOverOfLaneElement(event)
        );

        laneElementGfx?.addEventListener("drop", (event: any) => {
          onLaneMouseDown(
            lane.id,
            JSON.parse(event.dataTransfer.getData("application/json")),
            true
          );
        });
      }
    } catch (e: any) {
      console.error("error in add event listener", e);
    }
  };

  useEffect(() => {
    if (bpmnModeler) {
      const modeling = bpmnModeler.get("modeling");
      for (const [key, entities] of Object.entries(extensionElements || {})) {
        const element = bpmnModeler.get("elementRegistry").get(key);
        if (element) {
          modeling.updateLabel(
            element,
            entities.map((entity) => entity.name).join(",")
          );
        }
      }
    }
  }, [extensionElements, bpmnModeler]);

  const onLaneMouseDown = (
    selectedLane: string,
    data: any,
    fromDrop: boolean = false
  ) => {
    let isPresent: boolean = false;
    const extensionElementsOfThisLane =
      extensionElementsRef.current &&
      (extensionElementsRef.current[selectedLane] || []);
    if (extensionElementsRef.current) {
      if (extensionElementsOfThisLane && extensionElementsOfThisLane.length) {
        isPresent = Boolean(
          extensionElementsOfThisLane.find((element: any) => {
            return (
              element.idparticipant === data.idparticipant &&
              element.idparticipanttype === data.idparticipanttype
            );
          })
        );
      }
    }
    if (isPresent) {
      if (!fromDrop) {
        const selectedExtensionElementsList = [...extensionElementsOfThisLane];
        const updatedList = selectedExtensionElementsList.filter(
          (li) =>
            li.idparticipant !== data.idparticipant ||
            li.idparticipanttype !== data.idparticipanttype
        );
        extensionElementsRef.current[selectedLane] = updatedList;
      }
    } else {
      const updatedExtensionElements = [
        ...extensionElementsOfThisLane,
        {
          idparticipanttype: data.idparticipanttype,
          idparticipant: data.idparticipant,
          linkedto: selectedLane,
          name: data.name,
          assignmentmode: data.assignmentmode,
        },
      ];

      extensionElementsRef.current[selectedLane] = updatedExtensionElements;
    }
    setExtensionElements({ ...extensionElementsRef.current });
  };

  return {
    bpmnModeler,
    selectedLane,
    extensionSubprocesses: processModelData.extensionsubprocesses,
    extensionSubprocessesRef,
    extensionElementsRef,
    extensionElements,
    openDiagram,
    shouldShowDropZoneError,
    currentElementRef,
    handleCanvasViewPort,
    selectedElements,
    currentElement,
    onLaneMouseDown,
    processModelData,
    setProcessModelData,
    primaryPropertiesNameRef,
    originalXml,
    setExtensionElementsWithRef
  };
};

export default useBpmnModeler;
